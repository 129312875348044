import { useEffect, useState } from "react";
import i18n from "../../i18n"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";


type LanguageInfo = {
    [key: string]: { nativeName: string };
};

const languages: LanguageInfo = {
    en: { nativeName: "English" },
    fr: { nativeName: "Français" }
}

const LanguageSelector = () => {
    const {t} = useTranslation();
    const [language, setLanguage] = useState(i18n.language || process.env.REACT_APP_DEFAULT_LANGUAGE);

    return <FormControl style={{width:'90%'}}>
      <InputLabel id="language-selector-label">{t("languageselector.language")}</InputLabel>
      <Select
        id="language-selector-label"
        value={language}
        label={t("languageselector.language")}
        labelId="demo-simple-select-label"
        onChange={(event: SelectChangeEvent) => {
            i18n.changeLanguage(event.target.value);
            setLanguage(event.target.value);
        }}
        SelectDisplayProps={{style: {paddingTop: '12px'}}}
        variant="outlined"
    >
        {Object.keys(languages).map((lng: string) => (
            <MenuItem value={lng} key={lng} >{languages[lng].nativeName}</MenuItem>
        ))}
    </Select>
    </FormControl>
}

export default LanguageSelector
