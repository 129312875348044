import { Bar } from 'react-chartjs-2';
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme, colors } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Chart, ChartData, ChartDataset, ChartOptions, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables);

const Sales = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const commonDatasetOptions = {
    barPercentage: 0.5,
    barThickness: 12,
    maxBarThickness: 10,
    categoryPercentage: 0.5,
    borderRadius: 15
  } as ChartDataset<"bar">;

  const data = {
    datasets: [
      { ...commonDatasetOptions,
        backgroundColor: colors.indigo[500],
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'This year'
      },
      { ...commonDatasetOptions,
        backgroundColor: colors.grey[200],
        data: [11, 20, 12, 29, 30, 25, 13],
        label: 'Last year'
      }
    ],
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug']
  } as ChartData<"bar">;

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: { fontColor: theme.palette.text.secondary },
        gridLines: { display: false, drawBorder: false }
      },
      y: {
        ticks: { 
          fontColor: theme.palette.text.secondary,
          beginAtZero: true, min: 0
        },
        gridLines: {
          borderDash: [2], borderDashOffset: [2],
          color: theme.palette.divider, drawBorder: false,
          zeroLineBorderDash: [2], zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  } as ChartOptions<"bar">;

  return (
    <Card {...props}>
      <CardHeader
        action={<Button endIcon={<ArrowDropDownIcon />} size="small" variant="text" >Last 7 days</Button>}
        title={t("dashboard.latests_sales")}
      />
      <Divider/>
      <CardContent>
        <Box sx={{height: 400, position: 'relative'}}>
          <Bar data={data} options={options}/>
        </Box>
      </CardContent>
      <Divider/>
      <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
        <Button color="primary" endIcon={<ArrowRightIcon />} size="small" variant="text">Overview</Button>
      </Box>
    </Card>
  );
};

export default Sales;
