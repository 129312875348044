const Logo = (props: any) => (
  <img
    height='52px'
    alt="Logo"
    src="/static/logo-full.png"
    {...props}
  />
);

export default Logo;
