import React, {useEffect, useRef, useState} from 'react';
import {Recording, RecordingInvoiceStatus} from "../../services/RecordingService";
import {Dictionary, groupBy} from "../../utils/array.utils";
import {AgGridReact} from "ag-grid-react";
import {Typography} from "@mui/material";
import {ClinicalStudyEnum} from "../models/clinicalStudy";

interface DashboardInclusionTableProps {
    filteredPatientsRecordings?: Recording[],
}

interface CenterData {
    name: string;
    waitingForDataCount: number;
    invoicedCount: number;
    toBeInvoicedCount: number;
    notInvoicableCount: number;
}

const DashboardInclusionInvoicingGrid: React.FC<DashboardInclusionTableProps> = ({ filteredPatientsRecordings }) => {

    const [agGridData, setAgGridData] = useState<CenterData[]>([]);
    const gridRef = useRef<AgGridReact>(null);

    useEffect(() => {
        if (filteredPatientsRecordings) {
            const sesamePatientsRecordings: Recording[] = filteredPatientsRecordings.filter(sesameRecording => sesameRecording.clinicalStudy === ClinicalStudyEnum.SESAME);
            const groupedByCenter: { [key: string]: Recording[] } = sesamePatientsRecordings.reduce((acc:any, recording) => {
                if (!acc[recording.center]) {
                    acc[recording.center] = [];
                }
                acc[recording.center].push(recording);
                return acc;
            }, {});

            const data:CenterData[] = Object.keys(groupedByCenter).map(center => {
                const filteredRecordings = filteredPatientsRecordings.filter(recording => recording.center === center);
                const groupedByInvoicedStatus: Dictionary<Recording> = groupBy(filteredRecordings, 'invoiceStatus');
                return {
                    name: center,
                    waitingForDataCount: groupedByInvoicedStatus[RecordingInvoiceStatus.WAITING_FOR_INFO]?.length || 0,
                    invoicedCount: groupedByInvoicedStatus[RecordingInvoiceStatus.INVOICED]?.length || 0,
                    toBeInvoicedCount: groupedByInvoicedStatus[RecordingInvoiceStatus.TO_BE_INVOICED]?.length || 0,
                    notInvoicableCount: groupedByInvoicedStatus[RecordingInvoiceStatus.NOT_INVOICABLE]?.length || 0,
                };
            });
            setAgGridData(data);
        }
    }, [filteredPatientsRecordings]);



    const columnDefs = [
        { headerName: `Centre`, field: 'name', flex: 1 },
        { headerName: 'En attente', field: 'waitingForDataCount', flex: 1,},
        { headerName: 'Facturé', field: 'invoicedCount', flex: 1 },
        { headerName: 'A facturer', field: 'toBeInvoicedCount', flex: 1 },
        { headerName: 'Non facturable', field: 'notInvoicableCount', flex: 1 },
    ];

    return (
        <div style={{width: "100%", marginTop: "2%"}}>
            <Typography gutterBottom variant="h4" component="div">
                Facturation SESAME
            </Typography>
            <div className="ag-theme-alpine" style={{width: "100%", height: "25vh"}}>
                <AgGridReact
                    rowData={agGridData}
                    rowHeight={50}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                    enableCellChangeFlash={true}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                    }}>
                </AgGridReact>
            </div>
        </div>
    );
}

export default DashboardInclusionInvoicingGrid;
