import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { initializeHotkeys } from './Hotkeys';


const App: React.FC = () => {
  const routing = useRoutes(routes);

  React.useEffect( () => {
    //? Used to dispatch a ping. Will be connecting websockets later or have a new way of checking connections.
    initializeHotkeys();
  }, []);
  
  return (
    
      <ThemeProvider theme={theme}>
      <Suspense fallback="loading">
        <GlobalStyles/>
          {routing}
        </Suspense>
        </ThemeProvider>
    
  );
}

export default App;
