import * as d3 from 'd3';
import { nextId } from '../utils';

type HotkeyHandler = (kevent: KeyboardEvent) => any;

type Hotkey = {
    id: number, name: string | undefined
    key: string, modifier: string | undefined,
    handler: HotkeyHandler,
    enabled: boolean
};
let hotkeys: Hotkey[] = [];

type HotkeyGroup = {
    id: number, name: string,
    hotkeys: Hotkey[]
};
let groups: HotkeyGroup[] = [];

function callHandlerForKeyEvent(kevent: KeyboardEvent) {
    const eventKeys = hotkeys.filter( (hotkey) => {
        return hotkey.key === kevent.code &&
            ( !hotkey.modifier ||
                kevent.getModifierState(hotkey.modifier) )
    });

    for (const eventKey of eventKeys) {
        if (!eventKey.enabled) continue;
        eventKey.handler(kevent);
    }
}

function createHotkey(name: string, handler: HotkeyHandler, key: string, modifier?: string): void {
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === name )[0];
    if (hotKey) return; // If hotkey already exists, don't create again.

    const newHotkey: Hotkey = { id: nextId(hotkeys),
        name: name,
        key: key, modifier: modifier,
        handler: handler,
        enabled: true
    };
    hotkeys.push(newHotkey);
}

function disableHotkey(name: string) {
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === name )[0];
    hotKey.enabled = false;
}

function enableHotkey(name: string) {
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === name )[0];
    hotKey.enabled = true;
}

function updateHandlerForHotkey(name: string, handler: HotkeyHandler) {
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === name )[0];
    hotKey.handler = handler;
}

function createGroup(name: string): void {
    let group: HotkeyGroup = groups.filter( (group) => group.name === name )[0];
    if (group) return; // If already exists, don't create again.

    const newGroup: HotkeyGroup = { id: nextId(groups),
        name: name,
        hotkeys: []
    };
    groups.push(newGroup);
}

function addHotkeyToGroup(hotkeyName: string, groupName: string) {
    let group: HotkeyGroup = groups.filter( (group) => group.name === groupName )[0];
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === hotkeyName )[0];
    group.hotkeys.push(hotKey);
}

function removeHotkeyFromGroup(hotkeyName: string, groupName: string) {
    let group: HotkeyGroup = groups.filter( (group) => group.name === groupName )[0];
    let hotKey: Hotkey = hotkeys.filter( (hotkey) => hotkey.name === hotkeyName )[0];
    group.hotkeys.splice(group.hotkeys.indexOf(hotKey));
}

function enableGroup(name: string) {
    let group: HotkeyGroup = groups.filter( (group) => group.name === name )[0];
    for (const hotkey of group.hotkeys) hotkey.enabled = true;
}

function disableGroup(name: string) {
    let group: HotkeyGroup = groups.filter( (group) => group.name === name )[0];
    for (const hotkey of group.hotkeys) hotkey.enabled = false;
}

function initializeHotkeys() {
    const body = d3.select('body');
    body.on('keydown', (event) => {
        const kevent = event as KeyboardEvent;
        callHandlerForKeyEvent(kevent);
    });
}

export type { Hotkey, HotkeyGroup, HotkeyHandler };
export { initializeHotkeys,
    createHotkey, disableHotkey, enableHotkey, updateHandlerForHotkey,
    createGroup, addHotkeyToGroup, removeHotkeyFromGroup, enableGroup, disableGroup
};