import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import CustomerListResults from '../components/customer/CustomerListResults';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import { customers } from '../__mocks__/customers';

const CustomerList: React.FC = () => (
  <>
    <Helmet><title>Customers | Apneal</title></Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
        <CustomerListToolbar />
        <Box sx={{ pt: 3 }}>
          <CustomerListResults customers={customers} />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerList;
