import { Alert, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage } from '../../Redux/Types';
import { AuthenticationStatus, useAuth } from '../../hooks/auth';

const Login = () => {
  const navigate = useNavigate();
  const { passwordReset, authenticationStatus, logout } = useAuth();
  const { t } = useTranslation();

  React.useEffect( () => {
    if (authenticationStatus === AuthenticationStatus.LOGGED) navigate('/app/recordings', {replace: true});
  }, [authenticationStatus]);

  const handlePasswordReset  = async (values: {password: string}) => {
    const resp = await passwordReset({password:values.password});
    return resp;
  }

  const [errorOnLogin, setErrorOnLogin] = React.useState<ErrorMessage>({} as ErrorMessage);


  return (
    <>
      <Helmet><title>Login | Apneal</title></Helmet>
      <Box sx={{backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{password: '', passwordConfirmation: ''}}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('Password is required'),
              passwordConfirmation: Yup.string().max(255).required('Password confirmation is required')
                                      .oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
            onSubmit={ async values => {
              const resp = await handlePasswordReset(values); 
              if(resp && "errorType" in resp) {
                setErrorOnLogin(resp);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textSecondary" variant="h2">Modify your password</Typography>
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textSecondary" variant="body1">Your password has been assigned to you automatically, you need to create a new one. 
                  Please choose your new password.</Typography>
                </Box>
                {errorOnLogin && "errorCode" in errorOnLogin &&
                <Box>
                  <Alert severity="error">
                    {t([500, 502].includes(errorOnLogin.errorCode) ? "generic.500" : errorOnLogin.errorType, {ns: "errors"})}
                  </Alert>
                </Box>
                }
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                  fullWidth
                  helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                  label="Password confirmation"
                  margin="normal"
                  name="passwordConfirmation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passwordConfirmation}
                  variant="outlined"
                />

                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Update my password
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    href="mailto:contact@apneal.ai?subject=Account creation"
                    variant="h6"
                  >
                    Ask for an account creation
                  </Link>
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  <Link
                    onClick={ logout }
                    variant="h6"
                  >
                    Logout
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
