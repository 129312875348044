export interface Dictionary<T> {
    [key: string]: T[];
}

export function groupBy<T, K extends keyof T>(array: T[], property: K): Dictionary<T> {
    return array.reduce((acc: Dictionary<T>, obj: T) => {
        const key = String(obj[property]);
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {} as Dictionary<T>);
}