import {Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Recording} from '../../../services/RecordingService';
import {Comment} from "../../../Redux/ServerTypes";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import CommentService from "../../../services/CommentService";
import {useAuth} from "../../../hooks/auth";
import {CommentRenderer} from "./Comment";

export const CommentDialog = ({ data, open, commentNumber, setCommentNumber, handleClose }:
                                  { data: Recording, open: boolean, commentNumber: number, setCommentNumber: React.Dispatch<React.SetStateAction<number>>, handleClose: () => void }) => {

    const [comments, setComments] = useState<Comment[]>([]);
    const [value, setValue] = useState<string>('');
    const { user } = useAuth();

    useEffect(() => {
        if(open) {
            CommentService.getAllCommentsForScheduleId(data.id)
                .then((comments) => {
                    setComments(comments);
                })
                .catch((error) => {
                    console.error('Erreur lors du chargement des commentaires :', error);
                });
        }
    }, [open]);

    function Pluralize(commentCount: number) {
        return commentCount > 1 ? 'Comments' : 'Comment';
    }

    function getPatientNameSuffix(patientName: string) {
        return patientName.charAt(patientName.length - 1) === 's' ?  "'" : "'s";
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendComment();
        }
    }

    function sendComment(){
        const newComment = {
            userId: user?.id? user.id : 'undefined',
            scheduleId: data.id,
            content: value,
            createdAt: new Date(),
        }

        CommentService.createComment(newComment)
            .then((newComment)=> {
                setValue('')
                const updatedComments = [...comments, newComment];
                setComments(updatedComments);
                setCommentNumber(commentNumber+1)
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi du commentaire :', error);
            });
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {Pluralize(comments.length)} - {data.patientName}{getPatientNameSuffix(data.patientName)} recording ({data.id})
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers sx={{ height: 300 }}>
                <FormControl fullWidth>
                    {comments.length > 0 ?
                        <CommentRenderer user={user} comments={comments} setComments={setComments}></CommentRenderer>
                        : <Typography color="textPrimary" variant="body1">No comment stored for this recording</Typography>}
                </FormControl>
            </DialogContent>
            <ReactQuill theme={'snow'} placeholder={'Write a comment'} value={value} onChange={setValue} onKeyDown={handleKeyDown} style={{height: '120px' }}></ReactQuill>
            <Button onClick={sendComment}
                    color={"primary"}
                    size="small">
                Send
            </Button>
        </Dialog>
    )
}