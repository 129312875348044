import { createTheme, colors, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Shadows } from '@mui/material/styles/shadows';

const palette = {
  background: { default: '#F4F6F8',      paper: colors.common.white },
  primary:    { contrastText: '#ffffff', main: '#263d42' },
  text:       { primary: '#172b4d',      secondary: '#6b778c' }
} as PaletteOptions;

const typography = {
  h1: { fontWeight: 500, fontSize: 35, letterSpacing: '-0.24px' },
  h2: { fontWeight: 500, fontSize: 29, letterSpacing: '-0.24px' },
  h3: { fontWeight: 500, fontSize: 24, letterSpacing: '-0.06px' },
  h4: { fontWeight: 500, fontSize: 20, letterSpacing: '-0.06px' },
  h5: { fontWeight: 500, fontSize: 16, letterSpacing: '-0.05px' },
  h6: { fontWeight: 500, fontSize: 14, letterSpacing: '-0.05px' },
  overline: { fontWeight: 500 }
} as TypographyOptions;

const alpha31sp = "0 0 1px 0 rgba(0,0,0,0.31)";
const alpha25   = "rgba(0,0,0,0.25)";
const shadows = [
  `none`,                     `0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)`,
  `${alpha31sp}, 0 2px 2px -2px ${  alpha25}`, `${alpha31sp}, 0 3px 4px -2px ${  alpha25}`,
  `${alpha31sp}, 0 3px 4px -2px ${  alpha25}`, `${alpha31sp}, 0 4px 6px -2px ${  alpha25}`,
  `${alpha31sp}, 0 4px 6px -2px ${  alpha25}`, `${alpha31sp}, 0 4px 8px -2px ${  alpha25}`,
  `${alpha31sp}, 0 5px 8px -2px ${  alpha25}`, `${alpha31sp}, 0 6px 12px -4px ${ alpha25}`,
  `${alpha31sp}, 0 7px 12px -4px ${ alpha25}`, `${alpha31sp}, 0 6px 16px -4px ${ alpha25}`,
  `${alpha31sp}, 0 7px 16px -4px ${ alpha25}`, `${alpha31sp}, 0 8px 18px -8px ${ alpha25}`,
  `${alpha31sp}, 0 9px 18px -8px ${ alpha25}`, `${alpha31sp}, 0 10px 20px -8px ${alpha25}`,
  `${alpha31sp}, 0 11px 20px -8px ${alpha25}`, `${alpha31sp}, 0 12px 22px -8px ${alpha25}`,
  `${alpha31sp}, 0 13px 22px -8px ${alpha25}`, `${alpha31sp}, 0 14px 24px -8px ${alpha25}`,
  `${alpha31sp}, 0 16px 28px -8px ${alpha25}`, `${alpha31sp}, 0 18px 30px -8px ${alpha25}`,
  `${alpha31sp}, 0 20px 32px -8px ${alpha25}`, `${alpha31sp}, 0 22px 34px -8px ${alpha25}`,
  `${alpha31sp}, 0 24px 36px -8px ${alpha25}`
] as Shadows;

const theme = createTheme({
  palette: palette,
  shadows: shadows,
  typography: typography
});

export default theme;
