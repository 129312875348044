import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Avatar, Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { getInitials } from '../../../utils';
import { MockCustomer } from '../../__mocks__/customers';

type CustomerListResultsProps = { customers: MockCustomer[] };

const CustomerListResults: React.FC<CustomerListResultsProps> = ({customers, ...rest}) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([] as string[]);
  const [limit,               setLimit              ] = useState(10);
  const [page,                setPage               ] = useState( 0);

  const handleSelectAll = (event: React.ChangeEvent) => {
    let newSelectedCustomerIds: string[];
    if ((event.target as any).checked)
      newSelectedCustomerIds = customers.map(customer => customer.id);
    else
      newSelectedCustomerIds = [];

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: React.ChangeEvent, id: string) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds: string[] = [];

    if      (selectedIndex === -1)
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    else if (selectedIndex === 0) 
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    else if (selectedIndex === selectedCustomerIds.length - 1)
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    else if (selectedIndex > 0)
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event: React.ChangeEvent) => setLimit((event.target as any).value);
  const handlePageChange  = (event: React.MouseEvent | null, newPage: number) => setPage(newPage);

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox color="primary"
                    checked={selectedCustomerIds.length === customers.length}
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(0, limit).map(customer => (
                <TableRow hover key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox value="true"
                      checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                      <Avatar src={customer.avatarUrl} sx={{ mr: 2 }}>{getInitials(customer.name)}</Avatar>
                      <Typography color="textPrimary" variant="body1">{customer.name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{`${customer.address.city}, ${customer.address.state}, ${customer.address.country}`}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{moment(customer.createdAt).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination component="div" count={customers.length}
        onPageChange={handlePageChange} onRowsPerPageChange={handleLimitChange}
        page={page} rowsPerPage={limit} rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
