import { v4 as uuid } from 'uuid';

type MockAddress = { country: string, state: string, city: string, street: string };

type MockCustomer = {
  id: string, address: MockAddress,
  avatarUrl: string, createdAt: number,
  email: string, name: string, phone: string
};

const ekaterina = { id: uuid(),
  address: { country: 'USA', state: 'West Virginia', city: 'Parkersburg', street: '2849 Fulton Street' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_3.png', createdAt: 1555016400000,
  email: 'ekaterina.tankova@devias.io', name: 'Ekaterina Tankova', phone: '304-428-3097'
} as MockCustomer;

const cao = { id: uuid(),
  address: { country: 'USA', state: 'Bristow', city: 'Iowa', street: '1865  Pleasant Hill Road' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_4.png', createdAt: 1555016400000,
  email: 'cao.yu@devias.io', name: 'Cao Yu', phone: '712-351-5711'
} as MockCustomer;

const alexa = { id: uuid(),
  address: { country: 'USA', state: 'Georgia', city: 'Atlanta', street: '4894  Lakeland Park Drive' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_2.png', createdAt: 1555016400000,
  email: 'alexa.richardson@devias.io', name: 'Alexa Richardson', phone: '770-635-2682'
} as MockCustomer;

const anje = { id: uuid(),
  address: { country: 'USA', state: 'Ohio', city: 'Dover', street: '4158  Hedge Street' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_5.png', createdAt: 1554930000000,
  email: 'anje.keizer@devias.io', name: 'Anje Keizer', phone: '908-691-3242'
} as MockCustomer;

const clarke = { id: uuid(),
  address: { country: 'USA', state: 'Texas', city: 'Dallas', street: '75247' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_6.png', createdAt: 1554757200000,
  email: 'clarke.gillebert@devias.io', name: 'Clarke Gillebert', phone: '972-333-4106'
} as MockCustomer;

const adam = { id: uuid(),
  address: { country: 'USA', state: 'California', city: 'Bakerfield', street: '317 Angus Road' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_1.png', createdAt: 1554670800000,
  email: 'adam.denisov@devias.io', name: 'Adam Denisov', phone: '858-602-3409'
} as MockCustomer;

const ava = { id: uuid(),
  address: { country: 'USA', state: 'California', city: 'Redondo Beach', street: '2188  Armbrester Drive' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_7.png', createdAt: 1554325200000,
  email: 'ava.gregoraci@devias.io', name: 'Ava Gregoraci', phone: '415-907-2647'
} as MockCustomer;

const emilee = { id: uuid(),
  address: { country: 'USA', state: 'Nevada', city: 'Las Vegas', street: '1798  Hickory Ridge Drive' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_8.png', createdAt: 1523048400000,
  email: 'emilee.simchenko@devias.io', name: 'Emilee Simchenko', phone: '702-661-1654'
} as MockCustomer;

const kwak = { id: uuid(),
  address: { country: 'USA', state: 'Michigan', city: 'Detroit', street: '3934  Wildrose Lane' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_9.png', createdAt: 1554702800000,
  email: 'kwak.seong.min@devias.io', name: 'Kwak Seong-Min', phone: '313-812-8947'
} as MockCustomer;

const merrile = { id: uuid(),
  address: { country: 'USA', state: 'Utah', city: 'Salt Lake City', street: '368 Lamberts Branch Road' } as MockAddress,
  avatarUrl: '/static/images/avatars/avatar_10.png', createdAt: 1522702800000,
  email: 'merrile.burgett@devias.io', name: 'Merrile Burgett', phone: '801-301-7894'
} as MockCustomer;

const customers = [
  ekaterina, cao, alexa, anje, clarke,
  adam, ava, emilee, kwak, merrile
];

export { customers };
export type { MockAddress, MockCustomer };