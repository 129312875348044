import axios from "axios";
import {Recording, User} from "../Redux/ServerTypes";

export type AuditLog = {
    scheduleId: string;
    patientName: string;
    userId?: string;
    event: string;
    timestamp: Date;
    description: string;
    user: User;
    schedule: Recording;
}

const AuditLogService = {

    /**
     * Get All auditLog from the server
     * Route is protected for only ADMIN roles
     */
    listAuditLogs: async () => {
        return axios.get("/auditlog").then( (response) => {
            return response.data as AuditLog[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    listAuditLogsByScheduleId: async (scheduleId:string) => {
        return axios.get(`/auditlog/${scheduleId}`).then( (response) => {
            return response.data as AuditLog[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

}

export default AuditLogService;
