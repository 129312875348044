import React, { useState } from 'react';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { User } from '../../Redux/ServerTypes';
import UserService from '../../services/UserService';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const EditButton = ( params: ICellRendererParams ) => {
    console.log(params);
    return (
    <Button component={Link} color={"primary"} to={"/admin/users/edit/" + params.data.id}>
        Edit
    </Button>
  )};


const UsersGrid: React.FC = () => {
    const[users, setUsers] = React.useState<User[]>(); // [
    const [entries, setEntries] = React.useState<Record<string, any>[]>([]);
    const defaultCols = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
        editable: true,
    }
    // Column Definitions: Defines & controls grid columns.
    const [colDefs] = useState<ColDef[]>([
        {
            field: 'email',
        },
        {
            field: 'firstname',
        },
        {
            field: 'lastname',
        },
        {
            field: 'clinicalStudy'
        },
        {
            field: 'role',
        },
        {
            field: 'edit',
            cellRenderer: EditButton,
        }
    ]);


    React.useEffect( () =>  {
        const userList = UserService.getAllUsers();
        userList.then( (resp) => {
            setUsers(resp);
            const entries = resp.map( (user) => {
                return {email: user.email, role: user.role, id: user.id, firstname: user.firstname, lastname: user.lastname, clinicalStudy: user.clinicalStudy};
            });
            setEntries(entries);
        }).catch( (err) => {
            console.log(err);
        });
    }, []);

    return (
        <div className="ag-theme-alpine" style={{width: "100%", height: '81vh'}}>
        <AgGridReact
            onFirstDataRendered={ (event:any) => event.api.sizeColumnsToFit() }
            rowData={ entries }
            defaultColDef={defaultCols}
            columnDefs={colDefs}
            >
         </AgGridReact>
        </div>
    )
}

export default UsersGrid;