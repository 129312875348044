import { Outlet, useNavigate } from "react-router";
import { ModelRoles, useAuth } from "../../hooks/auth";
import { useEffect } from "react";

const AdminRoutesContainer: React.FC = () => {
  const {authenticationStatus, user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(user?.role !== ModelRoles.ADMIN) {
      navigate('/login');
    }
  }, [user, navigate]);

  return (
    <>
    <Outlet/>
    </>
    
  );
};

export default AdminRoutesContainer;