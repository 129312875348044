import { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';

const states = [
  { value: 'alabama',       label: 'Alabama'       },
  { value: 'new-york',      label: 'New York'      },
  { value: 'san-francisco', label: 'San Francisco' }
];

const AccountProfileDetails: React.FC = (props) => {
  const [values, setValues] = useState({
    firstName: 'Katarina',
    lastName: 'Smith',
    email: 'demo@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  });

  const handleChange = (event: React.ChangeEvent) => setValues({...values, [(event.target as any).name]: (event.target as any).value});

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile"/>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                helperText="Please specify the first name"
                label="First name" name="firstName" value={values.firstName}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                label="Last name" name="lastName" value={values.lastName}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                label="Email Address" name="email" value={values.email}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                type="number"
                label="Phone Number" name="phone" value={values.phone}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                label="Country" name="country" value={values.country}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth onChange={handleChange} variant="outlined"
                label="Select State" name="state" value={values.state}
                required select SelectProps={{ native: true }}
              >
                {states.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
          <Button color="primary" variant="contained">Save details</Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
