import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Button, Checkbox, Chip, FormControlLabel} from '@mui/material';
import {CellValueChangedEvent, ColDef, ICellRendererParams, StateUpdatedEvent} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {AgGridReact} from 'ag-grid-react';
import moment from 'moment';
import {parse} from 'tinyduration';
import {ModelRoles, useAuth, User} from '../../hooks/auth';
import CheckDefinitionService, {CheckDefinition, CheckType} from '../../services/CheckDefinitionService';
import RecordingService, {
    CheckDefinitionValue,
    Recording,
    RecordingActions,
    RecordingFormStatus,
    RecordingInvoiceStatus,
    RecordingPsgStatus,
    RecordingStatus
} from '../../services/RecordingService';
import {groupBy} from '../../utils/array.utils';
import {RecordingStatusTag} from '../components/status/Status';
import {PsgStatusTag} from '../components/status/PsgStatusTag';
import {RecordingGridMenu} from './renderer/RecordingMenuRenderer';
import {RecordingCommentRenderer} from "./renderer/RecordingCommentRenderer";
import {RecordingActionTag} from "../components/RecordingActionTag";
import UrlRenderer from "./UrlRenderer";
import {ClinicalStudyEnum} from "../models/clinicalStudy";
import {useLocation} from "react-router-dom";
import {RecordingInvoiceTag} from "../components/RecordingInvoiceTag";
import { FormStatusTag } from '../components/status/FormStatusTag';

interface RecordingsGridProps {
    selectedOption: string,
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>
}

export type StatusType = 'status' | 'action' | 'psgStatus' | 'formStatus' | 'invoiceStatus';

type StatusEnum =
    | RecordingStatus
    | RecordingActions
    | RecordingPsgStatus
    | RecordingFormStatus
    | RecordingInvoiceStatus;

const statusEnumMap = {
    status: RecordingStatus,
    action: RecordingActions,
    psgStatus: RecordingPsgStatus,
    formStatus: RecordingFormStatus,
    invoiceStatus: RecordingInvoiceStatus
};


const RecordingsGrid: React.FC<RecordingsGridProps> = ({selectedOption, setSelectedOption}) => {
    const [newRecordings, setNewRecordings] = useState<{ [key: string]: any }[]>([]);
    const [displayTest, setDisplayTest] = useState<boolean>(false);
    const gridRef = useRef<AgGridReact>(null);
    const [checkDefs, setCheckDefs] = useState<(ColDef<any, any> & { principalCheck?: boolean })[]>([]);
    const {user} = useAuth();
    const state = localStorage.getItem("agGridState") ? JSON.parse(localStorage.getItem("agGridState")!) : null;
    let patientCodeCheckId: string;
    const location = useLocation();
    const [openCommentDialog, setOpenCommentDialog] = useState<boolean>(false);
    const [currentScheduleId, setCurrentScheduleId] = useState<string>('');
    const refOpenCommentDialog = useRef(openCommentDialog);
    const refCurrentScheduleId = useRef(currentScheduleId);
    useEffect(() => {
        refOpenCommentDialog.current = openCommentDialog;
        refCurrentScheduleId.current = currentScheduleId;
    });
    const searchAndPinRow = useCallback(() => {
        const params = new URLSearchParams(location.search);
        const scheduleId = params.get('scheduleId');
        const openComments = params.get('openComments');

        if (scheduleId && gridRef.current?.api) {
            const pinnedRowNode = gridRef.current?.api.getRowNode(scheduleId);

            if (pinnedRowNode) {
                gridRef.current?.api.updateGridOptions({ pinnedTopRowData: [pinnedRowNode.data] });
            } else {
                console.warn(`Ligne avec scheduleId ${scheduleId} non trouvée.`);
            }

            if (openComments === 'true') {
                setCurrentScheduleId(scheduleId);
                setOpenCommentDialog(true);
            }
        }
    }, [location]);

    const closeCommentDialog = () => {
        if (refCurrentScheduleId.current && refOpenCommentDialog.current) {
            setOpenCommentDialog(false);
            setCurrentScheduleId('');
            gridRef.current?.api.updateGridOptions({ pinnedTopRowData: [] });
        }
    };

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
            const activeElement = document.activeElement as HTMLElement;
            if (
                activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable
            ) {
                return;
            } else {
                event.preventDefault();
            }
        }
    });

    const updateRecordings = (checkDef: CheckDefinition, event: {
        data: { id: string; };
        newValue: string | boolean;
    }) => {
        const rowNode = gridRef.current!.api.getRowNode(event.data.id);
        if (rowNode) {
            const updatedRecord = {...rowNode.data};
            const checks = updatedRecord.checks || [];
            const updatedCheck = {
                checkDefinitionId: checkDef.id,
                scheduleId: event.data.id,
                value: event.newValue ? event.newValue.toString() : ''
            };
            const existingCheckIndex = checks.findIndex((check: {
                checkDefinitionId: string;
            }) => check.checkDefinitionId === checkDef.id);

            if (existingCheckIndex !== -1) {
                checks[existingCheckIndex] = updatedCheck;
            } else {
                checks.push(updatedCheck);
            }

            updatedRecord.checks = checks;
            rowNode.setData(updatedRecord);
        }
    };

    React.useEffect(() => {
        //Load recordings associated to user
        RecordingService.getRecordingsForUser().then((localRecordings: Recording[]) => {
            //Load check definitions
            CheckDefinitionService.getCheckDefinitions().then((checkDefinitionsAll) => {
                const localCheckDefinition = groupBy(checkDefinitionsAll, 'id')
                const checkDefBuilder: (ColDef<any, any> & { principalCheck?: boolean })[] = [];
                for (const checkDefinition in localCheckDefinition) {
                    if (localCheckDefinition[checkDefinition].length === 0) {
                        continue;
                    }
                    const checkDef = localCheckDefinition[checkDefinition][0];
                    if (checkDef.technicalId === "patientCode") {
                        patientCodeCheckId = checkDef.id
                    }

                    const urlRegex = /(https?:\/\/[^\s]+)/;
                    const isUrl = (text: string) => {
                        return urlRegex.test(text);
                    };

                    const colDefWithPrincipalCheck = {
                        field: checkDef.technicalId,
                        headerTooltip: checkDef.definition,
                        headerName: checkDef.label,
                        editable: true,
                        onCellValueChanged: (event: CellValueChangedEvent) => {
                            RecordingService.updateRecordingCheck(checkDef.technicalId, event.newValue, event.data.id).then(() =>
                                updateRecordings(checkDef, event)
                            )
                        },
                        cellRendererSelector: (params: ICellRendererParams) => {
                            let cellValue: string = '';
                            params.data.checks.forEach((check: any) => {
                                if (check.checkDefinitionId === checkDef.id) {
                                    cellValue = check.value;
                                }
                            });
                            if (checkDef.type === CheckType.BOOLEAN) {
                                return {component: 'agCheckboxCellRenderer'};
                            } else if (isUrl(cellValue)) {
                                return {component: UrlRenderer};
                            } else {
                                return {component: 'default'};
                            }
                        },
                        cellEditor: checkDef.type === CheckType.BOOLEAN ? 'agCheckboxCellEditor' : 'null',
                        principalCheck: checkDef.principalCheck
                    };

                    checkDefBuilder.push(colDefWithPrincipalCheck as ColDef<any, any> & { principalCheck?: boolean });
                }
                setCheckDefs(checkDefBuilder);
                loadAggridData(localRecordings, localCheckDefinition, displayTest)
            });
        })
    }, [displayTest]);

    const updateColumnsDefs = () => {
        if (gridRef.current?.api) {
            if (selectedOption === 'allChecks') {
                gridRef.current!.api.setGridOption("columnDefs", colDefsBuilder.concat(checkDefs));
            } else if (selectedOption === 'principalChecks') {
                const principalCheckDefs = checkDefs.filter(checkDef => checkDef.principalCheck);
                gridRef.current!.api.setGridOption("columnDefs", colDefsBuilder.concat(principalCheckDefs));
            } else {
                gridRef.current!.api.setGridOption("columnDefs", colDefsBuilder);
            }
        }
    }

    const onGridReady = () => {
        updateColumnsDefs();
        searchAndPinRow();
    }

    React.useEffect(() => {
        updateColumnsDefs();
    }, [selectedOption, checkDefs]);

    const updateStatus = <T extends StatusEnum>(
        id: string | undefined,
        status: T,
        statusType: StatusType
    ) => {
        if (status === undefined) return;
        const rowNode = gridRef.current!.api.getRowNode(id ?? "")!;
        const enumMapping = statusEnumMap[statusType] as unknown as Record<string, string>;
        rowNode.setDataValue(statusType, enumMapping[status]);
    };

    const loadAggridData = React.useCallback((recordings: Recording[], localCheckDefinition, displayTest: boolean) => {
        const newRecordings = recordings.filter(
            (recording: Recording) => {
                if (displayTest) {
                    return true;
                }
                return recording.status.toString() !== RecordingStatus[RecordingStatus.TEST];
            }
        ).map((recording: Recording) => {

            let patientNumber = recording.patientName;
            const patientCodeCheck: CheckDefinitionValue | undefined = recording.checks?.find((check: CheckDefinitionValue) => {
                return check.checkDefinitionId === patientCodeCheckId;
            });

            if (patientCodeCheck && patientCodeCheck.value?.length !== 0) {
                patientNumber = patientCodeCheck.value;
            }

            const aggridData: { [key: string]: any } = {
                patientNumber: patientNumber,
                owner: recording.userAppelation,
                startTime: dateDisplay(recording.startDate),
                scheduledDuration: durationDisplay(recording.duration),
                ...recording
            }

            recording.checks?.forEach((check) => {
                const checkDef = localCheckDefinition[check.checkDefinitionId];

                if (checkDef === undefined || checkDef.length === 0) {
                    return;
                }
                const checkDefinition = checkDef[0];
                if (checkDefinition.type === CheckType.BOOLEAN) {
                    aggridData[checkDefinition.technicalId] = check.value === "true";
                } else
                    aggridData[checkDefinition.technicalId] = check.value;
            });
            return aggridData;
        });
        setNewRecordings(newRecordings)
    }, [displayTest]);

    const dateDisplay = (date: string): string => {
        const dateObj = new Date(Date.parse(date));
        return moment(dateObj).format("YYYY-MM-DD HH:mm:ss");
    }

    const durationDisplay = (duration: string): string => {
        try {
            const d = parse(duration);
            return `${d.hours}h ${d.minutes}m`;
        } catch (e) {
            return duration;
        }
    }

    const colDefsBuilder: ColDef<any, any>[] = [];
    colDefsBuilder.push(
        {
            field: 'menu',
            cellRenderer: (params: ICellRendererParams) => {
                return <RecordingGridMenu user={user} data={params.data} rowNode={params.node}
                                          callback={updateStatus}></RecordingGridMenu>;
            },
            width: 80,
            pinned: "left",
            resizable: false,
            sortable: false,
            filter: false,
        },
        {
            field: 'patientNumber',
            pinned: "left",
            width: 155,
        },
        {
            field: 'owner',
            headerName: "User in charge"
        },
        {
            field: 'status',
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data !== undefined)
                    return <RecordingStatusTag status={params.data.status}></RecordingStatusTag>;
                return <></>;
            },
            filter: 'agSetColumnFilter',
            headerName: "Statut global",
            width: 180,
        },
        {
            field: 'psgStatus',
            filter: 'agSetColumnFilter',
            cellRenderer: (params: ICellRendererParams) => {
                if (user && params.data !== undefined) {
                    if (params.data.clinicalStudy === ClinicalStudyEnum.EITHEALTH) {
                        return <Chip label={'Not applicable'} style={{backgroundColor: 'lightgray', color: 'white'}}/>
                    }
                    return <PsgStatusTag data={params.data} user={user} rowNode={params.node}
                                         callback={updateStatus}></PsgStatusTag>;
                }
                return <></>;
            },
            width: 145,
        },
        {
            field: 'formStatus',
            filter: 'agSetColumnFilter',
            cellRenderer: (params: ICellRendererParams) => {
                if (user && params.data !== undefined) {
                    if (params.data.clinicalStudy === ClinicalStudyEnum.EITHEALTH) {
                        return <Chip label={'Not applicable'} style={{backgroundColor: 'lightgray', color: 'white'}}/>
                    }
                    return <FormStatusTag data={params.data} user={user} rowNode={params.node}
                                          callback={updateStatus}></FormStatusTag>;
                }
                return <></>;
            },
            width: 145,
        }
    );

    const generateInvoiceStatusColumn = (user: User) => ({
        field: 'invoiceStatus',
        cellRenderer: (params: ICellRendererParams) => {
            if (params.data !== undefined) {
                if (params.data.clinicalStudy === ClinicalStudyEnum.EITHEALTH) {
                    return <Chip label={'Not applicable'} style={{backgroundColor: 'lightgray', color: 'white'}}/>
                }
                return <RecordingInvoiceTag data={params.data} user={user} rowNode={params.node}
                                            callback={updateStatus}></RecordingInvoiceTag>;
            }
            return <></>;
        },
        filter: 'agSetColumnFilter',
        headerName: "Invoice status",
        width: 180,
    });

    if (user?.role === ModelRoles.ADMIN) {
        colDefsBuilder.push(
            {
                field: 'action',
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.data !== undefined)
                        return <RecordingActionTag data={params.data} rowNode={params.node}
                                                   callback={updateStatus}></RecordingActionTag>;
                    return <></>;
                },
                filter: 'agSetColumnFilter',
                headerName: "Action",
                width: 180,
            },
            generateInvoiceStatusColumn(user),
            {
                field: 'comments',
                cellRenderer: (params: ICellRendererParams) => {
                    return <RecordingCommentRenderer data={params.data} openPopUp={refOpenCommentDialog.current}
                                                     scheduleId={refCurrentScheduleId.current}
                                                     closePopUp={closeCommentDialog}></RecordingCommentRenderer>;

                },
                width: 125
            }
        );
    }

    if (user?.role === ModelRoles.CLINICAL_STUDY_ADMIN && user.clinicalStudy === ClinicalStudyEnum.SESAME) {
        colDefsBuilder.push(generateInvoiceStatusColumn(user));
    }

    colDefsBuilder.push(
        {
            field: 'startTime',
            width: 180,
        },
        {
            field: 'clinicalStudy',
            width: 145,
        },
        {
            field: 'scheduledDuration',
            width: 180,
        }
    );

    const onStateUpdated = useCallback((params: StateUpdatedEvent) => {
        if (params.api.getColumnDefs() !== undefined)
            localStorage.setItem("agGridColDefs", JSON.stringify(params.api.getColumnDefs()));
        localStorage.setItem("agGridState", JSON.stringify(params.state));
    }, []);

    const onResetGrid = () => {
        if (gridRef.current !== null) {
            setSelectedOption('noCheck');
            gridRef.current.api.setFilterModel(null);
            const resetColumnState = colDefsBuilder.concat(checkDefs).map(colDef => ({
                colId: colDef.field || '',
                sort: null,
                sortIndex: null,
                hide: colDef.hide || false
            })).filter(state => state.colId);
            gridRef.current.api.applyColumnState({
                state: resetColumnState,
                applyOrder: true,
            });
        }
    };

    const onBtnExport = useCallback(() => {
        if (gridRef.current !== null) {
            gridRef.current.api.exportDataAsCsv();
        }
    }, []);

    return (
        <div className="ag-theme-alpine" style={{width: "100%", height: "82vh"}}>
            <Box sx={{mt: 3}} display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                <FormControlLabel
                    control={<Checkbox
                        color="primary"
                        checked={displayTest}
                        onClick={() => {
                            setDisplayTest(!displayTest)
                        }}/>}
                    label="Afficher les données de test"/>
                <div>
                    <Button onClick={onResetGrid}
                            color={"primary"}
                            size="small">
                        Reset grid
                    </Button>
                    <Button onClick={onBtnExport}
                            color={"primary"}
                            size="small">
                        Export CSV
                    </Button>
                </div>
            </Box>
            {checkDefs?.length > 0 && newRecordings?.length > 0 &&
                <AgGridReact
                    rowData={newRecordings}
                    getRowId={params => params.data.id}
                    suppressScrollOnNewData={true}
                    rowHeight={50}
                    ref={gridRef}
                    initialState={state}
                    onStateUpdated={onStateUpdated}
                    onGridReady={onGridReady}
                    enableCellChangeFlash={true}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: true,
                    }}>
                </AgGridReact>
            }
        </div>
    )
}

export default RecordingsGrid;
