import { Backdrop, Box, Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import FileService from "../../services/FileService";
import { truncateFileName } from "../../utils/string.utils";
import { ScheduleContext } from "../context/ScheduleContext";
import theme from "../theme";
import { CircularProgressWithLabel } from "./CircleProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '90%',
		height: '500px',
		border: `2px dashed #000000`,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		position: 'relative',
	},
	icon: {
		fontSize: 48,
	},
	uploadButton: {
		width: '100%',
		opacity: 1,
		cursor: 'pointer',
		marginTop: "auto !important"
	},
}));



interface FileUploaderProps {
	onUploadSuccess: (data: any) => void;
	onUploadFailure: () => void;
	type: string;
}

/// Uploads files.
export const FileUploader: React.FC<FileUploaderProps> = ({type, onUploadSuccess, onUploadFailure }) => {
	const [files, setFiles] = React.useState<File[]>([]);
	const schedule = useContext(ScheduleContext);
	const [uploading, setUploading] = React.useState<boolean>(false);
	const classes = useStyles(theme);
	const [progress, setProgress] = React.useState<number>(0);
	/// Frame into which files can be drag and dropped.
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles: File[]) => {
			setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
		}
	} as DropzoneOptions);

	const handleUpload = async () => {
		setUploading(true);
		FileService.uploadFiles( schedule.id, type, files, (progressEvent: ProgressEvent) => {
			if (progressEvent.lengthComputable) {
				const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setProgress(percentComplete);
				return;
			}
		}, onUploadSuccess, () => {onUploadFailure(); setUploading(false)});
	};

	return (
		<>
			<Box className={classes.root} {...getRootProps()} flex="flex">
				<Box marginTop={20}>
					<Typography align="center" color={isDragActive ? 'textSecondary' : 'textPrimary'}>
						<Button className={classes.uploadButton} variant="contained" color="primary">Parcourir </Button>
						Ou faites glisser les documents
					</Typography>
					<input {...getInputProps()} />
					<Typography align="center" color={isDragActive ? 'textSecondary' : 'textPrimary'} marginTop={5}>
						{files.length > 0 && (
							<>
								<h4>Fichiers sélectionnés:</h4>
								<Box sx={{
									mb: 10,
									display: "flex",
									flexDirection: "column",
									height: 230,
									overflow: "hidden",
									overflowY: "scroll",
									}}>
									{files.map((file, index) => (
										<Box key={index}>{truncateFileName(file.name, 60)}</Box>
									))}
								</Box>
									
							</>
						)}
					</Typography>
				</Box>
			</Box>
			<Box width="90%">
				<Button className={classes.uploadButton}
					variant="contained"
					color="primary"
					onClick={handleUpload}
					disabled={files.length == 0 || uploading}>
					Upload
				</Button>
			</Box>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute'}}
				open={uploading}
			>
				<CircularProgressWithLabel value={progress} />
			</Backdrop>
		</>
	);
};


