export function truncateFileName(fileName: string, maxLength: number) {
    // Check if the fileName length is greater than the maxLength
    if (fileName.length > maxLength) {
        // Calculate how many characters to show from the start and end
        let startChars = Math.ceil((maxLength - 3) / 2); // -3 for the ellipsis
        let endChars = Math.floor((maxLength - 3) / 2);
        // Construct the truncated fileName
        return fileName.substring(0, startChars) + '...' + fileName.substring(fileName.length - endChars);
    }
    // Return the original fileName if it doesn't exceed maxLength
    return fileName;
}
