import { IconButton } from "@mui/material";
import React, {useEffect, useState} from "react";
import LoupeIcon from '@mui/icons-material/Loupe';
import {CommentDialog} from "../../components/comments/CommentDialog";
import {Recording} from '../../../services/RecordingService';

export const RecordingCommentRenderer = ({data, openPopUp, scheduleId, closePopUp}:
                                             {
                                                 data: Recording,
                                                 openPopUp: boolean,
                                                 scheduleId: string,
                                                 closePopUp: () => void
                                             }) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [commentNumber, setCommentNumber] = useState<number>(data.comments.length);

    useEffect(() => {
        if (openPopUp && data.id === scheduleId) {
            setOpenDialog(true);
        }
    }, [openPopUp, scheduleId, data.id]);

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        closePopUp();
    };

    return <>
        <IconButton onClick={handleClick}>
            <LoupeIcon/>
        </IconButton>
        ({commentNumber})

        <CommentDialog open={openDialog} commentNumber={commentNumber}
                       setCommentNumber={setCommentNumber} data={data} handleClose={handleClose}></CommentDialog>
    </>
}