import { v4 as uuid } from 'uuid';

type MockProduct = { id: string, createdAt: string, description: string, media: string, title: string, totalDownloads: string };

const dropbox = { id: uuid(), createdAt: '27/03/2019',
  description: 'Dropbox is a file hosting service that offers cloud storage, file synchronization, a personal cloud.',
  media: '/static/images/products/product_1.png', title: 'Dropbox', totalDownloads: '594'
} as MockProduct;

const medium = { id: uuid(), createdAt: '31/03/2019',
  description: 'Medium is an online publishing platform developed by Evan Williams, and launched in August 2012.',
  media: '/static/images/products/product_2.png', title: 'Medium Corporation', totalDownloads: '625'
} as MockProduct;

const slack = { id: uuid(), createdAt: '03/04/2019',
  description: 'Slack is a cloud-based set of team collaboration tools and services, founded by Stewart Butterfield.',
  media: '/static/images/products/product_3.png', title: 'Slack', totalDownloads: '857'
} as MockProduct;

const lyft = { id: uuid(), createdAt: '04/04/2019',
  description: 'Lyft is an on-demand transportation company based in San Francisco, California.',
  media: '/static/images/products/product_4.png', title: 'Lyft', totalDownloads: '406'
} as MockProduct;

const github = { id: uuid(), createdAt: '04/04/2019',
  description: 'GitHub is a web-based hosting service for version control of code using Git.',
  media: '/static/images/products/product_5.png', title: 'GitHub', totalDownloads: '835'
} as MockProduct;

const squarespace = { id: uuid(), createdAt: '04/04/2019',
  description: 'Squarespace provides software as a service for website building and hosting. Headquartered in NYC.',
  media: '/static/images/products/product_6.png', title: 'Squarespace', totalDownloads: '835'
} as MockProduct;

const products = [dropbox, medium, slack, lyft, github, squarespace];

export { products };
export type { MockProduct };