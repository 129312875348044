import { GlobalState } from "./Redux/Types";

export function getInitials(name = '') {
    return name.replace(/\s+/, ' ')
    .split(' ').slice(0, 2)
    .map((v) => v && v[0].toUpperCase()).join('');
}

export function nextId(lt_list: any) {
    const last_id = lt_list.reduce(
        (last_id: number, lt_el: any) => Math.max(lt_el.id, last_id), -1
    );
    return last_id + 1
}

/**
 * Function emulating the sleep behavior from other languages. Essentially suspends execution until a given time if used with "await".
 * @param ms {number} Milliseconds to sleep for
 */
export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const labelTypeSelector = (state: GlobalState) =>  state.labelType.labelTypes;
export const labelSelector     = (state: GlobalState) =>  state.label.labels;
export const cameraSelector    = (state: GlobalState) =>  state.camera;

export const authSelector       = (state: GlobalState) => state.authentication.authenticated;
export const roleSelector       = (state: GlobalState) => state.authentication.role;
export const authingSelector    = (state: GlobalState) => state.authentication.authenticating;

export const contentWindowSelector = (state: GlobalState) => state.contentWindow;

export const bufferSelector     = (state: GlobalState) => state.currentRecording.buffer;
export const wabufferSelector   = (state: GlobalState) => state.currentRecording.wabuffer;
export const startDateSelector  = (state: GlobalState) => {
    if (state.currentRecording.recording === undefined) return 0;
    return state.currentRecording.recording.start;
}
export const ridSelector        = (state: GlobalState) => {
    if (state.currentRecording.recording === undefined) return "";
    return state.currentRecording.recording.id;
}
export const recordingSelector = (state: GlobalState) => state.currentRecording.recording;
export const audioSelector     = (state: GlobalState) => state.currentRecording.audio;
export const bufferingSelector = (state: GlobalState) => state.currentRecording.buffering;

export const recordingsSelector  = (state: GlobalState) => state.recordings.recordings;
export const patientsSelector    = (state: GlobalState) => state.patients.patients;
export const usersSelector       = (state: GlobalState) => state.users.users;
export const invitationsSelector = (state: GlobalState) => state.invitations.invitations;
export const filesSelector       = (state: GlobalState) => state.files.files;

export const channelSelector    = (state: GlobalState) => state.channels.channels;
export const fitRequestSelector = (state: GlobalState) => state.channels.fit;
