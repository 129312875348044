import { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField } from '@mui/material';

const SettingsPassword: React.FC = (props: any) => {
  const [values, setValues] = useState({password: '', confirm: ''});

  const handleChange = (event: React.ChangeEvent) => setValues({...values, [(event.target as any).name]: (event.target as any).value});

  return (
    <form {...props}>
      <Card>
        <CardHeader subheader="Update password" title="Password"/>
        <Divider/>
        <CardContent>
          <TextField fullWidth onChange={handleChange}
            label="Password" margin="normal"
            name="password" type="password"
            value={values.password} variant="outlined"
          />
          <TextField fullWidth onChange={handleChange}
            label="Confirm password" margin="normal"
            name="confirm" type="password"
            value={values.confirm} variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
          <Button color="primary" variant="contained">Update</Button>
        </Box>
      </Card>
    </form>
  );
};

export default SettingsPassword;
