import axios from "axios";

export interface CheckDefinition {
    id: string;
    technicalId: string;
    label: string;
    definition: string;
    principalCheck: boolean;
    type: CheckType;
}

export enum CheckType {
    BOOLEAN = "BOOLEAN",
    STRING = "STRING",
    DATETIME = "DATETIME"
}

const CheckDefinitionService = {
    /**
     * Get All checkDefinitions
     */
    getCheckDefinitions: async () => {
        return axios.get("/check-definition").then( (response) => {
            return response.data as CheckDefinition[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },
}

export default CheckDefinitionService;