import axios from "axios";
import RecordingService from "./RecordingService";



const FileService = {

    /**
     * Upload files to the server
     */
    uploadFiles: async (scheduleId: string, type: string, files: File[], onUploadProgress: (progressEvent: ProgressEvent) => void, onSuccess: (data: any) => void, onFailure: () => void) => {
        //As we want every file to be uploaded, we use Promise.all to wait for all the promises to be resolved
        Promise.all(files.map(file => 
            // Return a new Promise for each file
            new Promise((resolve, reject) => {
                RecordingService.getSignedUrl(scheduleId, type, file.name)
                    .then((signedUrl) => {
                        axios.put(signedUrl, file, {
                            headers: {
                                'Content-Type': file.type
                            },
                            onUploadProgress: (progressEvent) => {
                                onUploadProgress(progressEvent);
                            }
                        })
                        .then(response => {
                            console.log(response);
                            resolve(response); // Resolve the promise on success
                        })
                        .catch(error => {
                            console.log(error);
                            onFailure();
                            reject(error); // Reject the promise on failure
                        });
                    })
            })
        ))
        .then(() => {
            onSuccess({})
        })
    }
}

export default FileService;
