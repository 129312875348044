import { Navigate } from 'react-router-dom';
import AdminRoutesContainer from './components/AdminRoutes';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import AdminPage from './pages/AdminPage';
import ChartPage from './pages/ChartPage';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import DashboardInclusion from './pages/DashboardInclusion';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PSGPage from './pages/PSGPage';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';
import TwoFAValidationPage from './pages/TwoFAValidationPage';
import UserCreationPage from './pages/UserCreationPage';
import UsersPage from './pages/UsersPage';
import FormUploadPage from './pages/FormUploadPage';
import AuditTrailGrid from "./AGGrids/AuditTrailGrid";


const routes = [
  {
    path: 'admin',
    element: <DashboardLayout />,
    children: [
      {
        path: 'users',
        element: <AdminRoutesContainer />,
        children: [
          { path: '', element: <UsersPage /> },
          { path: 'create', element: <UserCreationPage /> },
          { path: 'edit/:id', element: <UserCreationPage /> }
        ]
      },
    ]
  },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'recordings', element: <ChartPage /> },
      { path: 'recordings/:id/psg-upload', element: <PSGPage /> },
      { path: 'recordings/:id/form-upload', element: <FormUploadPage /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'all_dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'admin', element: <AdminPage /> },
      { path: 'dashboard', element: <DashboardInclusion /> },
      { path: 'audit_trail', element: <AuditTrailGrid /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'login-validation', element: <TwoFAValidationPage /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/recordings" /> }, //was dashboard
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
