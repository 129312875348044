import {Dialog, DialogContent, DialogTitle, FormControl, IconButton} from "@mui/material";
import {AuditLog} from "../../services/AuditLogSevice";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

export const AuditTrailDialog = ({ data, open, handleClose }: { data: AuditLog, open: boolean, handleClose: () => void }) => {

    const dateDisplay = (date: Date): string => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const modifiedData = {
        scheduleId: data.scheduleId,
        patientName: data.schedule.patientName,
        userId: data.userId,
        userEmail: data.user?.email ?? 'lambda',
        actionTaken: data.event,
        dateTime: dateDisplay(data.timestamp),
        otherInfo: JSON.parse(data.description)
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                JSON file
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                <FormControl fullWidth>
                    <div>
                        <pre>
                            {JSON.stringify(modifiedData, null, 2)}
                        </pre>
                    </div>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
}