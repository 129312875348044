import { Alert, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage } from '../../Redux/Types';
import { AuthenticationStatus, useAuth } from '../../hooks/auth';
import { useSnackbar } from '../../hooks/snackbar';

const TwoFAValidationPage = () => {
  
  const navigate = useNavigate();
  const { twoFAValidation, twoFaResend, authenticationStatus } = useAuth();
  const {setSnackbar} = useSnackbar();

  React.useEffect( () => {
    if (authenticationStatus === AuthenticationStatus.LOGGED) navigate('/app/recordings', {replace: true});
  }, [authenticationStatus, navigate]);

  const handleTwoFAValidation = async (values: {code: string}) => {
    const resp = await twoFAValidation({code:values.code});
    return resp;
  }
  const {t} = useTranslation();
  const [errorOnValidation, setErrorOnValidation] = React.useState<ErrorMessage>({} as ErrorMessage);

    return (
    <>
      <Helmet><title>Login Validation | Apneal</title></Helmet>
      <Box sx={{backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{code: ""}}
            validationSchema={Yup.object().shape({
              code: Yup.string().min(4).max(4).required('Validation code is required'),
            })}
            onSubmit={ async values => {
              const resp = await handleTwoFAValidation(values)
              if(resp && "errorType" in resp) {
                setErrorOnValidation(resp);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">Insert your validation code</Typography>
                </Box>
                {errorOnValidation && "errorCode" in errorOnValidation &&
                <Box>
                  <Alert severity="error">
                    {t([500, 502].includes(errorOnValidation.errorCode) ? "generic.500" : errorOnValidation.errorType, {ns: "errors"})}
                  </Alert>
                </Box>
                }
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code}
                  label="Validation code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.code}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                    You didn't received a validation code ?
                  {' '}
                  <Link
                    href="#"
                    onClick={async () => {
                      await twoFaResend();
                      setSnackbar({message: "Un nouveau code vous a été envoyé."});
                    }}
                    variant="h6"
                  >
                    Resend a validation code. 
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default TwoFAValidationPage;