export class ClinicalStudy {
    name: string;
    description: string;
    psgUpload: boolean;
    formUpload: boolean;

    constructor(name: string, description: string, psgUpload: boolean, formUpload: boolean) {
        this.name = name;
        this.description = description;
        this.psgUpload = psgUpload;
        this.formUpload = formUpload;
    }

    // Additional methods related to ClinicalStudy can be added here
}

export enum ClinicalStudyEnum {
    EITHEALTH = "EITHEALTH",
    SESAME = "SESAME",
    OTHER = "OTHER"

}

// Example usage:
const eitHealth = new ClinicalStudy("EITHealth", "EITHealth EASY", false, false);
const sesame = new ClinicalStudy("SESAME", "Ilab SESAME", true, true);
const other = new ClinicalStudy("OTHER", "other use cases", true, true);

// Storing instances in an array or an object for easy access
export const clinicalStudies = new Map<ClinicalStudyEnum, ClinicalStudy>([
    [ClinicalStudyEnum.EITHEALTH, eitHealth],
    [ClinicalStudyEnum.SESAME, sesame],
    [ClinicalStudyEnum.OTHER, other]
]);
