import {Box, Container, FormControl, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import RecordingsGrid from '../AGGrids/RecordingsGrid';
import {useAuth} from "../../hooks/auth";

const ChartPage: React.FC = () => {

    const [selectedOption, setSelectedOption] = useState(() => {
        return localStorage.getItem('selectedOption') || 'noCheck';
    });
    const { user } = useAuth();
    const handleSelectionChange = (event:any) => {
        const newValue = event.target.value;
        setSelectedOption(newValue);
        localStorage.setItem('selectedOption', newValue);
    };

  return (<>
    <Helmet><title>Recordings | Apneal</title></Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
          <Box sx={{display: 'flex', alignContent: "center", alignItems: "center", justifyContent: 'space-between', marginBottom:3}}>
              <Typography variant="h4" gutterBottom>
              Retrouvez la liste de vos patients et leurs différents status.
              </Typography>
              {user?.role === "ADMIN" &&
                  <FormControl sx={{ m: 1, width: '11rem' }} size="small">
                      <InputLabel>Choose check selection</InputLabel>
                      <Select
                          label="Choose check selection"
                          value={selectedOption}
                          onChange={handleSelectionChange}
                      >
                          <MenuItem value={'noCheck'}>No check</MenuItem>
                          <MenuItem value={'principalChecks'}>Principal checks</MenuItem>
                          <MenuItem value={'allChecks'}>All checks</MenuItem>
                      </Select>
                  </FormControl>
              }
          </Box>
          <RecordingsGrid selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
      </Container>
    </Box>
  </>);
}

export default ChartPage;
