import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage } from '../../Redux/Types';
import { AuthenticationStatus, useAuth } from '../../hooks/auth';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { passwordRecovery, authenticationStatus } = useAuth();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.LOGGED) navigate('/app/recordings', { replace: true });
  }, [authenticationStatus]);

  const handleForgotPassword = async (values: { email: string }) => {
    const resp = await passwordRecovery({ email: values.email });
    setDialogOpen(true);
    return resp;
  }
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [errorOnLogin, setErrorOnLogin] = React.useState<ErrorMessage>({} as ErrorMessage);


  return (
    <>
      <Helmet><title>Login | Apneal</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            })}
            onSubmit={async values => {
              const resp = await handleForgotPassword(values);
              if (resp && "errorType" in resp) {
                setErrorOnLogin(resp);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textSecondary" variant="h2">Ask for a new password</Typography>
                  </Box>
                  {errorOnLogin && "errorCode" in errorOnLogin &&
                    <Box>
                      <Alert severity="error">
                        {t([500, 502].includes(errorOnLogin.errorCode) ? "generic.500" : errorOnLogin.errorType, { ns: "errors" })}
                      </Alert>
                    </Box>}
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined" />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Ask for a new password
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Don&apos;t have an account?
                    {' '}
                    <Link
                      href="mailto:contact@apneal.ai?subject=Account creation"
                      variant="h6"
                    >
                      Ask for an account creation
                    </Link>
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    <Link
                      href="/Login"
                      variant="h6"
                    >
                      Login
                    </Link>
                  </Typography>
                  <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      You have requested a password change
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        You will receive an email with a new temporary password. 
                        On your next login, you will be asked to change your password. You can now go back to the login page. 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => navigate("/login")}>Go to login page</Button>
                    </DialogActions>
                  </Dialog>
                </form>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
