import { Box, Button, Container, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { Center, User } from '../../Redux/ServerTypes';
import { ModelRoles } from '../../hooks/auth';
import CenterService from '../../services/CenterService';
import UserService from '../../services/UserService';
import { Dictionary, groupBy } from '../../utils/array.utils';
import { ClinicalStudyEnum } from '../models/clinicalStudy';

const UsersCreationOrEditionPage: React.FC = () => {
  const navigate = useNavigate();
  const [displayedCenters, setDisplayedCenters] = useState<Center[]>([]);
  const [initialValues, setInitialValues] = useState<User>({} as User);
  const [centers, setCenters] = useState<Dictionary<Center>>({});
  const request = useParams();
  const userId = request?.id ?? null;


  useEffect(() => {

    CenterService.getAllCenters().then((centersList) => {
      setCenters(groupBy(centersList, 'clinicalStudy'));
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (userId !== null) {
      UserService.getUserById(userId).then((user) => {
        if (user) {
          if (user.clinicalStudy !== undefined && user.clinicalStudy in centers)
            setDisplayedCenters(centers[user.clinicalStudy]);
          setInitialValues(user);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [centers]);

  return (<>
    <Helmet><title>{userId === null ? "User Creation" : "User Edition"} | Apneal</title></Helmet>
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
      <Container maxWidth={false}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: initialValues?.email ?? '',
            firstname: initialValues?.firstname ?? '',
            lastname: initialValues?.lastname ?? '',
            appelation: initialValues?.appelation ?? '',
            role: initialValues?.role ?? '',
            centerId: initialValues?.centerId ?? '',
            clinicalStudy: initialValues?.clinicalStudy ?? ''
          }}

          validationSchema={
            Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              firstname: Yup.string().max(255).required('First name is required'),
              lastname: Yup.string().max(255).required('Last name is required'),
              appelation: Yup.string().max(255).required('appelation is required (Dr, Mr, Ms, etc.)'),
              role: Yup.mixed().oneOf([Object.values(ModelRoles)], 'This field must be checked'),
              centerId: Yup.string().when("role", (role) => {
                return role == ModelRoles.PRACTITIONER
                  ? Yup.string().required('The center must be selected')
                  : Yup.string().notRequired();
              }),
              clinicalStudy: Yup.mixed<ClinicalStudyEnum>().required("The clinical study must be selected")

            })}

          onSubmit={async values => {
            if (userId !== null) {
              const resp = await UserService.updateUser(userId, values as User);
              if (resp && "errorType" in resp) {
                return;
              }
            } else {
              const resp = await UserService.createUser(values as User);
              if (resp && "errorType" in resp) {
                return;
              }
            }
            navigate('/admin/users', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Add a new user
                </Typography>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(touched.appelation && errors.appelation)}
                    fullWidth
                    helperText={touched.appelation && errors.appelation}
                    label="Appelation"
                    margin="normal"
                    name="appelation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.appelation}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    label="First name"
                    margin="normal"
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstname}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Last name"
                    margin="normal"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastname}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>

                  <TextField
                    error={Boolean(touched.role && errors.role)}
                    fullWidth
                    helperText={touched.role && errors.role}
                    label="Choose a role"
                    margin="normal"
                    name="role"
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.role}
                    variant="outlined"
                  >
                    <MenuItem value={ModelRoles.ADMIN}>Admin</MenuItem>
                    <MenuItem value={ModelRoles.CLINICAL_STUDY_ADMIN}>Clinical study admin</MenuItem>
                    <MenuItem value={ModelRoles.PRACTITIONER}>Practitioner</MenuItem>
                  </TextField>

                  <FormLabel id="demo-radio-buttons-group-label">Clinical study</FormLabel>
                  <Box sx={{ paddingLeft: 4 }}>
                    <RadioGroup name="clinicalStudy" onChange={
                      (event) => {
                        const selectedClinicalStudy = event.target.value;
                        setDisplayedCenters(centers[selectedClinicalStudy]);
                        handleChange(event);
                      }
                    }>
                      {Object.keys(ClinicalStudyEnum).map((key) => (
                        <FormControlLabel
                          key={key}
                          value={key}
                          checked={values.clinicalStudy === key}
                          control={<Radio />}
                          label={ClinicalStudyEnum[key as keyof typeof ClinicalStudyEnum]}></FormControlLabel>
                      ))}
                    </RadioGroup>
                  </Box>


                  <TextField
                    error={Boolean(touched.centerId && errors.centerId)}
                    fullWidth
                    helperText={touched.centerId && errors.centerId}
                    label="Choose a center"
                    margin="normal"
                    name="centerId"
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.centerId}
                    variant="outlined"
                  >
                    {displayedCenters?.map((center) => (
                      <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Box sx={{ py: 4, paddingLeft: 4 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {userId !== null ? "Edit User" : "Create user"}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </>);
}

export default UsersCreationOrEditionPage;
