import AddBox from '@mui/icons-material/AddBox';
import { Box, Button, Container } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import UsersGrid from '../AGGrids/UsersGrid';
;


const UsersPage: React.FC = () => {
  const navigate = useNavigate();
  
  return (<>
    <Helmet><title>Users | Apneal</title></Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
        <Button component={Link} color={"primary"} to="/admin/users/create">
          Create a new user
        </Button>
        <UsersGrid/>
      </Container>
    </Box>
  </>);
}

export default UsersPage;
