import moment from 'moment';
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const userlocation = `${user.city} ${user.country}`;
const usertime     = `${moment().format('hh:mm A')} ${user.timezone}`;

const AccountProfile: React.FC = (props: any) => (
  <Card {...props}>
    <CardContent>
      <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <Avatar src={user.avatar} sx={{height: 100, width: 100}}/>
        <Typography color="textPrimary"   variant="h3" gutterBottom>{user.name}   </Typography>
        <Typography color="textSecondary" variant="body1"          >{userlocation}</Typography>
        <Typography color="textSecondary" variant="body1"          >{usertime}    </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button color="primary" variant="text" fullWidth>Upload picture</Button>
    </CardActions>
  </Card>
);

export default AccountProfile;
