import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GetAppIcon from '@mui/icons-material/GetApp';
import { MockProduct } from '../../__mocks__/products';

type ProductCardProps = { product: MockProduct };
const ProductCard: React.FC<ProductCardProps> = ({product, ...rest}) => (
  <Card
    sx={{display: 'flex', flexDirection: 'column', height: '100%'}}
    {...rest}
  >
    <CardContent>
      <Box sx={{display: 'flex', justifyContent: 'center', pb: 3}}>
        <Avatar alt="Product" src={product.media} variant="square"/>
      </Box>
      <Typography align="center" color="textPrimary" gutterBottom variant="h4">
        {product.title}
      </Typography>
      <Typography align="center" color="textPrimary" variant="body1">
        {product.description}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />
    <Divider />
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Grid item sx={{alignItems: 'center', display: 'flex'}}>
          <AccessTimeIcon color="action" />
          <Typography color="textSecondary" display="inline" sx={{ pl: 1 }} variant="body2">
            Updated 2hr ago
          </Typography>
        </Grid>
        <Grid item sx={{alignItems: 'center', display: 'flex'}}>
          <GetAppIcon color="action" />
          <Typography color="textSecondary" display="inline" sx={{ pl: 1 }} variant="body2">
            {product.totalDownloads}
            {' '}
            Downloads
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

ProductCard.propTypes = { product: PropTypes.object.isRequired as PropTypes.Validator<MockProduct> };

export default ProductCard;
