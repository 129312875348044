import { Box, Divider, Drawer, Hidden, IconProps, List, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Activity as ActivityIcon,
    AlertCircle as AlertCircleIcon,
    AlignLeft as AlignLeftIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Paperclip as PaperclipIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    Table as TableIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Icon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ModelRoles, useAuth } from '../../hooks/auth';
import LanguageSelector from './LanguageSelector';
import NavItem from './NavItem';


type NavBarItem = { href: string, icon: React.FC<IconProps> | Icon, title: string };


const  dashboardItem: NavBarItem = { href: '/app/all_dashboard' , icon:    BarChartIcon, title: "menu.dashboard"};
const  auditTrailItem: NavBarItem = { href: '/app/audit_trail' , icon:    AlignLeftIcon, title: "Audit trail"};
const recordingsItem: NavBarItem = { href: '/app/recordings', icon:    ActivityIcon, title: 'Enregistrements'};
const        psgItem: NavBarItem = { href: '/app/psg'       , icon:   PaperclipIcon, title: 'Fichiers de PSG'};
const   patientsItem: NavBarItem = { href: '/app/customers' , icon:       UsersIcon, title: 'Patients'       };
const      usersItem: NavBarItem = { href: '/admin/users' ,     icon:       UsersIcon, title: 'Utilisateurs'   };
const    accountItem: NavBarItem = { href: '/app/account'   , icon:        UserIcon, title: 'Mon Compte'     };
const   productsItem: NavBarItem = { href: '/app/products'  , icon: ShoppingBagIcon, title: 'Products'       };
const   settingsItem: NavBarItem = { href: '/app/settings'  , icon:    SettingsIcon, title: 'Paramètres'     };
const      loginItem: NavBarItem = { href: '/login'         , icon:        LockIcon, title: 'Login'          };
const   registerItem: NavBarItem = { href: '/register'      , icon:    UserPlusIcon, title: 'Register'       };
const      adminItem: NavBarItem = { href: '/app/admin'     , icon: AlertCircleIcon, title: 'Administration' };
const dashboardInclusionItem: NavBarItem = { href: '/app/dashboard', icon:    TableIcon, title: 'Dashboard'};

const adminItems = [dashboardInclusionItem, recordingsItem, usersItem];
const medicItems = [recordingsItem];
const clinicalStudiesAdminItems = [dashboardInclusionItem, recordingsItem];

const version = process.env.REACT_APP_VERSION;

const DashboardSidebar = ({ onMobileClose, openMobile }: any) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [items, setItems] = React.useState<NavBarItem[]>([]);
  const location = useLocation();
  const role = user?.role;

  React.useEffect( () => {
    let newItems: NavBarItem[];

    switch(role) {
      case ModelRoles.ADMIN:        newItems = adminItems; break;
      case ModelRoles.PRACTITIONER: newItems = medicItems; break;
      case ModelRoles.CLINICAL_STUDY_ADMIN: newItems = clinicalStudiesAdminItems; break;
      default:                      newItems = [];         break;
    }
    
    setItems(newItems);
  }, [role]);

  React.useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2}}>
        <Typography color="textPrimary" variant="h5"     >{user?.email}</Typography>
        <Typography color="textSecondary" variant="body2">To be updated</Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map(item => 
            <NavItem href={item.href} key={item.title} title={t(item.title)} icon={item.icon as React.FC<IconProps>}/>
          )}
        </List>
      </Box>
      <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
          width: '100%'
        }}>
        <LanguageSelector/>
      </Box>
      <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2}}>
        <Typography align="center" color="textSecondary" variant="caption">
          {`v${version}`}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export type { NavBarItem };
export default DashboardSidebar;
