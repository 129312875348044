import { Alert, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage } from '../../Redux/Types';
import { AuthenticationStatus, useAuth } from '../../hooks/auth';

const Login = () => {
  const navigate = useNavigate();
  const { login, authenticationStatus } = useAuth();
  const { t } = useTranslation();

  React.useEffect( () => {
    if (authenticationStatus === AuthenticationStatus.LOGGED) navigate('/app/recordings', {replace: true});
    if(authenticationStatus === AuthenticationStatus.NEED_PASSWORD_CHANGE) navigate('/reset-password', {replace: true});
  }, [authenticationStatus]);

  const handleLogin  = async (values: {email: string, password: string}) => {
    const resp = await login({email:values.email, password:values.password});
    return resp;
  }

  const [errorOnLogin, setErrorOnLogin] = React.useState<ErrorMessage>({} as ErrorMessage);


  return (
    <>
      <Helmet><title>Login | Apneal</title></Helmet>
      <Box sx={{backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{email: '', password: ''}}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={ async values => {
              const resp = await handleLogin(values); 
              if(resp && "errorType" in resp) {
                setErrorOnLogin(resp);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textSecondary" variant="h2">Log in Apneal</Typography>
                </Box>
                {errorOnLogin && "errorCode" in errorOnLogin &&
                <Box>
                  <Alert severity="error">
                    {t([500, 502].includes(errorOnLogin.errorCode) ? "generic.500" : errorOnLogin.errorType, {ns: "errors"})}
                  </Alert>
                </Box>
                }
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    href="mailto:contact@apneal.ai?subject=Account creation"
                    variant="h6"
                  >
                    Ask for an account creation
                  </Link>
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  <Link
                    href="/forgot-password"
                    variant="h6"
                  >
                    Forgot your password?
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
