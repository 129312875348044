import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';

const SettingsNotifications: React.FC = (props: any) => (
  <form {...props}>
    <Card>
      <CardHeader subheader="Manage the notifications" title="Notifications"/>
      <Divider />
      <CardContent>
        <Grid container spacing={6} wrap="wrap">
          <Grid item md={4} sm={6} xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography color="textPrimary" gutterBottom variant="h6">Notifications</Typography>
            <FormControlLabel label="Email"
              control={<Checkbox color="primary" defaultChecked/>}
            />
            <FormControlLabel label="Push Notifications"
              control={<Checkbox color="primary" defaultChecked/>}
            />
            <FormControlLabel label="Text Messages"
              control={<Checkbox/>}
            />
            <FormControlLabel label="Phone calls"
              control={<Checkbox color="primary" defaultChecked/>}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography color="textPrimary" gutterBottom variant="h6">Messages</Typography>
            <FormControlLabel label="Email"
              control={<Checkbox color="primary" defaultChecked/>}
            />
            <FormControlLabel label="Push Notifications"
              control={<Checkbox />}
            />
            <FormControlLabel label="Phone calls"
              control={<Checkbox color="primary" defaultChecked/>}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
        <Button color="primary" variant="contained">Save</Button>
      </Box>
    </Card>
  </form>
);

export default SettingsNotifications;
