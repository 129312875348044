import axios from "axios";
import {Comment} from "../Redux/ServerTypes";

const CommentService = {

    /**
     * Get All comments for a schedule from the server
     * Route is protected for only ADMIN roles
     */
    getAllCommentsForScheduleId: async (id: string) => {
        return axios.get(`/comment/${id}`).then( (response) => {
            return response.data as Comment[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    createComment: async (comment: Comment) => {
        return axios.post("/comment", comment).then( (response) => {
            return response.data as Comment;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    updateComment: async (id: string , content: string) => {
        return axios.put("/comment/update-comment", {content: content, id: id}).then( (response) => {
            return response.data as Comment;
        }).catch( (error) => {
            throw new Error(error);
        });
    },
}

export default CommentService;
