import axios from 'axios';
import { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, ErrorType } from '../Redux/Types';
import {ClinicalStudyEnum} from "../Application/models/clinicalStudy";



interface PasswordRecoveryParams {
    email: string;
}

interface PasswordResetParams {
    password: string;
}


interface LoginParams {
    email: string;
    password: string;
}

interface TwoFAValidationParams {
    code: string;
}

export interface User {
    id: string;
    email: string;
    password_change?:boolean;
    role?: ModelRoles
    clinicalStudy?: ClinicalStudyEnum;
}

interface TokenResponse {
    access_token: string;
    user: User
}

interface TokenPayload {
    userId: string,
    email: string,
    role: ModelRoles,
    twoFAvalid: boolean,
    timestamp: string
}

/**
 * Identifier of available user roles.
 */
export enum ModelRoles {
    ADMIN        = 'ADMIN',
    CLINICAL_STUDY_ADMIN = 'CLINICAL_STUDY_ADMIN',
    PRACTITIONER = 'PRACTITIONER',
    NONE         = 'NONE'
}

export enum AuthenticationStatus {
    UNLOGGED = "UNLOGGED",
    LOGGED   = "LOGGED",
    TWO_FA_VALIDATION = "TWO_FA_VALIDATION",
    NEED_PASSWORD_CHANGE = "NEED_PASSWORD_CHANGE"
}

const clientid = `${process.env.REACT_APP_API_CLIENT_ID}`;
const secret = `${process.env.REACT_APP_API_CLIENT_SECRET}`;
const instanceid = navigator.userAgent;


const UserContext = createContext<AuthContextType>({} as AuthContextType);

export const UserProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const navigate = useNavigate();

    const setInitialAuthenticationStatus = ():AuthenticationStatus => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('access_token');
        if(token !== null) {
            const payload:TokenPayload = JSON.parse(atob(token?.split('.')[1] || ''));
            if(user && token && payload.twoFAvalid && !JSON.parse(user).password_change) {
                return AuthenticationStatus.LOGGED;
            } else if (!payload.twoFAvalid) {
                return AuthenticationStatus.TWO_FA_VALIDATION;
            } else if (user && JSON.parse(user).password_change) {
                return AuthenticationStatus.NEED_PASSWORD_CHANGE;
            }
        }
        return AuthenticationStatus.UNLOGGED;
    }

    const setInitialUser = ():User => {
        return JSON.parse(localStorage.getItem('user') || '{}');

    }

    const [user, setUser] = useState<User | null>(setInitialUser());
    const [authenticationStatus, setAuthenticationStatus] = useState<AuthenticationStatus>(setInitialAuthenticationStatus());

    

    //Make sure every call to the API has a 401 gets the user logged off
    axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response?.status === 401 && authenticationStatus === AuthenticationStatus.LOGGED) {
                logout();
            }
            return Promise.reject(error);
        }
    );

    
    const login = async ({ email, password }: LoginParams):Promise<ErrorMessage | void> => {
        return await axios.post<TokenResponse>(
            "/auth/signin",
            {
                email: email,
                password: password,
                clientId: clientid,
                secret:   secret,
                instanceId: instanceid
            }).then((res) => {
            console.log('a',res.data)
            localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('access_token', res.data.access_token);
                setAuthenticationStatus(AuthenticationStatus.TWO_FA_VALIDATION);
                setUser(res.data.user);
                navigate('/login-validation');
            }).catch((err) => {
                return { errorType: ErrorType.LOGIN_WRONG_CREDENTIALS, errorCode: err.response?.status??500 }
            });
    };

    const passwordRecovery = async ({ email }: PasswordRecoveryParams):Promise<ErrorMessage | void> => {
        return await axios.get<TokenResponse>(
            "/password-recovery/request?email="+email).then(() => {
               return;
            }).catch((err) => {
                return { errorType: ErrorType.PASSWORD_RECOVERY_WRONG_CREDENTIALS, errorCode: err.response?.status??500 }
            });
    };

    const twoFaResend = async ():Promise<ErrorMessage | void> => {
        return await axios.get<TokenResponse>(
            "auth/2fa-validation-resend").then(() => {
               return;
            }).catch((err) => {
                return { errorType: ErrorType.GENERIC, errorCode: err.response?.status??500 }
            });
    };

    const passwordReset = async ({ password }: PasswordResetParams):Promise<ErrorMessage | void> => {
        return await axios.put<TokenResponse>(
            `/users/${user?.id}/update/password?password=${password}`)
            .then(() => {
                if(user !== null){
                    const newUser = {...user, password_change: false}
                    setUser(newUser);
                    localStorage.setItem('user', JSON.stringify(newUser));

                }
                setAuthenticationStatus(AuthenticationStatus.LOGGED);
                const destination = user?.role === ModelRoles.PRACTITIONER ? 'app/recordings' : 'app/dashboard';
                navigate(destination);

                return;
            }).catch((err) => {
                return { errorType: ErrorType.PASSWORD_RECOVERY_WRONG_CREDENTIALS, errorCode: err.response?.status??500 }
            });
    };

    const setLoggedStatus = (user: User):void =>{
        if(user.password_change) {
            setAuthenticationStatus(AuthenticationStatus.NEED_PASSWORD_CHANGE);
        } else {
            setAuthenticationStatus(AuthenticationStatus.LOGGED);
        }
    }

    const twoFAValidation = async ({ code }: TwoFAValidationParams) => {
        return await axios.post<TokenResponse>(
            "/auth/2fa-validation",
            {
                code: code,
                instanceId: instanceid
            }).then((res) => {
                localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('access_token', res.data.access_token); 
                setLoggedStatus(res.data.user);   
                
                setUser(res.data.user);
            const destination = user?.role === ModelRoles.PRACTITIONER ? 'app/recordings' : 'app/dashboard';
            navigate(destination);

        }).catch((err) => {
                console.log("taest");
                return { errorType: ErrorType.TWO_FA_VALIDATION_ERROR, errorCode: err.response?.status??500 }
            });
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        setUser(null);
        setAuthenticationStatus(AuthenticationStatus.UNLOGGED);
        navigate('/login');
    };

    const value = {
            authenticationStatus,
            user,
            login,
            logout,
            twoFAValidation,
            passwordRecovery,
            passwordReset,
            twoFaResend
        }

    return <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>

};

interface AuthContextType {
    authenticationStatus?: AuthenticationStatus;
    user?: User | null;
    login: (params: LoginParams) => Promise<ErrorMessage | void>;
    logout: () => void;
    twoFaResend: () => Promise<ErrorMessage | void>;
    twoFAValidation: (params: TwoFAValidationParams) => Promise<ErrorMessage | void>;
    passwordRecovery: (params: PasswordRecoveryParams) => Promise<ErrorMessage | void>;
    passwordReset: (params: PasswordResetParams) => Promise<ErrorMessage | void>;
}

export const useAuth = ():AuthContextType => {
    return useContext(UserContext)
};