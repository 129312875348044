import { Snackbar } from '@mui/material';
import { ReactNode, createContext, useContext, useState } from 'react';

/**
 * This hook provides a way to show a simple snackbar message from any component.
 * It is usefull to give user feedback about the result of an action.
 */

type SnackbarProps = {
    message: string;
    
};

const SnackbarContext = createContext<SnackBarContextType>({} as SnackBarContextType);

export const SnackbarProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [snackbar, setSnackbar] = useState<SnackbarProps>({} as SnackbarProps);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }
    
        setSnackbar({} as SnackbarProps);
    };


    return <SnackbarContext.Provider value={{ setSnackbar }}>
            {children}
            <Snackbar open={snackbar.message !== undefined}
            autoHideDuration={5000}
            onClose={handleClose}

            message={snackbar.message}></Snackbar>
        </SnackbarContext.Provider>

};

interface SnackBarContextType {
    setSnackbar: (props: SnackbarProps) => void;
}

export const useSnackbar = ():SnackBarContextType => {
    return useContext(SnackbarContext)
};

