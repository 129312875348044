import {Chip, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import RecordingService, {
    Recording,
    RecordingPsgStatus,
} from "../../../services/RecordingService";
import {ModelRoles, User} from "../../../hooks/auth";
import {IRowNode} from "ag-grid-community";
import {StatusType} from "../../AGGrids/RecordingsGrid";

export const PsgStatusTag = ({data, user, rowNode, callback}: {
    data: Recording,
    user: User,
    rowNode: IRowNode<Recording>,
    callback: (id: string | undefined, psgStatus: RecordingPsgStatus, statustag: StatusType) => void
}) => {
    let tagColor;
    let tagText;

    const [anchorEl, setAnchorEl] = useState(null);
    const psgEnum = RecordingPsgStatus[data.psgStatus as keyof typeof RecordingPsgStatus];

    const handleClick = (event: any) => {
        if (user.role === ModelRoles.ADMIN) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (psgStatus: RecordingPsgStatus) => {
        RecordingService.updateRecordingPsgStatus(data.id, psgStatus).then(() => {
            callback(rowNode.id, psgStatus, 'psgStatus');
        })
        handleClose();
    };

    switch (psgEnum) {
        case RecordingPsgStatus.UPLOADED:
            tagColor = 'green';
            tagText = 'Uploaded';
            break;
        case RecordingPsgStatus.PSG_OK:
            tagColor = 'darkGreen';
            tagText = 'PSG ok';
            break;
        case RecordingPsgStatus.ALERT_RAISED:
            tagColor = 'red';
            tagText = 'Alerte levée';
            break;
        case RecordingPsgStatus.NOT_UPLOADED:
            tagColor = 'orange';
            tagText = 'Non uploaded';
            break;
        default:
            tagColor = 'gray';
            tagText = 'Not applicable';
    }

    return (
        <>
            <Chip label={tagText} clickable={user.role === ModelRoles.ADMIN} onClick={handleClick}
                  style={{backgroundColor: tagColor, color: 'white'}}/>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(RecordingPsgStatus.UPLOADED)}>Uploaded</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingPsgStatus.PSG_OK)}>Psg ok</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingPsgStatus.ALERT_RAISED)}>Alerte levée</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingPsgStatus.NOT_UPLOADED)}>Non uploaded</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingPsgStatus.NOT_APPLICABLE)}>Non applicable</MenuItem>
            </Menu>
        </>
    );
};



