import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './Application/App';
import { initAxios } from './axios';
import { UserProvider } from './hooks/auth';
import { SnackbarProvider } from './hooks/snackbar';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';


initAxios();

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
      <SnackbarProvider>
      <UserProvider>
            <App/>
        </UserProvider>
      </SnackbarProvider>
        
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
