import axios from "axios";
import { Center } from "../Redux/ServerTypes";



const CenterService = {

    /**
     * Get All users from the server 
     * Route is protected for only ADMIN roles
     */
    getAllCenters: async () => {
        return axios.get("/centers").then( (response) => {
            return response.data as Center[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

}

export default CenterService;
