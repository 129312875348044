import * as axios from 'axios';

function initAxios(): void {
    axios.default.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
    axios.default.defaults.headers.common["Content-Type"] = "application/json";


    axios.default.interceptors.request.use(
        config => {
            if( config.url?.indexOf('amazonaws.com') != -1) {
                return config;
            }
            if(config.headers){
                config.headers['ngrok-skip-browser-warning'] = 'true';
            }
            if (config.headers && localStorage.getItem('access_token')) {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
}



const clientid = `${process.env.REACT_APP_API_CLIENT_ID}`;
const secret = `${process.env.REACT_APP_API_CLIENT_SECRET}`;
const instanceid = navigator.userAgent;

export { clientid, initAxios, instanceid, secret };
