import {
    Box,
    Container,
} from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { ModelRoles, useAuth } from "../../hooks/auth";
import RecordingService, { Recording, RecordingStatus } from "../../services/RecordingService";
import DashboardInclusionInvoicingGrid from "../AGGrids/DashboardInclusionInvoingGrid";
import DashboardInclusionCard from "../components/dashboardInclusion/DashboardInclusionCard";
import DashboardInclusionTable from "../components/dashboardInclusion/DashboardInclusionTable";
import { ClinicalStudyEnum } from "../models/clinicalStudy";

function filterRecordingByStudy(recording:Recording[], study:ClinicalStudyEnum): Recording[]{
    return recording.filter(
        (recording: Recording) => recording.clinicalStudy.toString() === study
    )
}

function filterRecordingByStatus(recording:Recording[], status:RecordingStatus): Recording[]{
    return recording.filter(
        (recording: Recording) => recording.status.toString() === status
    )
}

const DashboardInclusion: React.FC = () => {

    const [filteredPatientsRecordings, setFilteredPatientsRecordings] = useState<Recording[]>();
    const [easyPatientsCount, setEasyPatientsCount] = useState<number>(0);
    const [easyExcludedPatientsCount, setEasyExcludedPatientsCount] = useState<number>(0);
    const [sesamePatientsCount, setSesamePatientsCount] = useState<number>(0);
    const [sesameExcludedPatientsCount, setSesameExcludedPatientsCount] = useState<number>(0);
    const [clinicalStudyAdmin, setClinicalStudyAdmin] = useState<ClinicalStudyEnum>();
    const {user} = useAuth();

    useEffect(() => {
        RecordingService.getRecordingsForUser().then((patientRecordings: Recording[]) => {
            const filteredPatientsRecordings: Recording[] = patientRecordings.filter((recording) => recording.status.toString() !== RecordingStatus[RecordingStatus.TEST])
            const excludedfilteredPatientsRecordings: Recording[] = filterRecordingByStatus(filteredPatientsRecordings, RecordingStatus[RecordingStatus.EXCLUDED])
            setFilteredPatientsRecordings(filteredPatientsRecordings);

            let easyPatientsCount: number = filteredPatientsRecordings.length;
            let sesamePatientsCount: number = filteredPatientsRecordings.length;
            let easyExcludedPatientCount: number = excludedfilteredPatientsRecordings.length;
            let sesameExcludedPatientCount: number = excludedfilteredPatientsRecordings.length;

            if (user && user.role === ModelRoles.ADMIN) {
                const filteredPatientsEithealth: Recording[] = filterRecordingByStudy(filteredPatientsRecordings, ClinicalStudyEnum.EITHEALTH)
                easyPatientsCount = filteredPatientsEithealth.length;
                const filteredEasyExcludedPatients: Recording[] = filterRecordingByStatus(filteredPatientsEithealth, RecordingStatus[RecordingStatus.EXCLUDED])
                easyExcludedPatientCount = filteredEasyExcludedPatients.length;

                const filteredPatientsSesame: Recording[] = filterRecordingByStudy(filteredPatientsRecordings, ClinicalStudyEnum.SESAME)
                sesamePatientsCount = filteredPatientsSesame.length;
                const filteredSesameExcludedPatients: Recording[] = filterRecordingByStatus(filteredPatientsSesame, RecordingStatus[RecordingStatus.EXCLUDED])
                sesameExcludedPatientCount = filteredSesameExcludedPatients.length;

            } else if (filteredPatientsRecordings.length > 0) {
                const clinicalStudyStr = filteredPatientsRecordings[0].clinicalStudy;
                if (Object.values(ClinicalStudyEnum).includes(clinicalStudyStr as ClinicalStudyEnum)) {
                    setClinicalStudyAdmin(clinicalStudyStr as ClinicalStudyEnum);
                }
            }

            setEasyPatientsCount(easyPatientsCount);
            setEasyExcludedPatientsCount(easyExcludedPatientCount);
            setSesamePatientsCount(sesamePatientsCount);
            setSesameExcludedPatientsCount(sesameExcludedPatientCount);
        });
    }, []);

    return (<>
        <Helmet><title>DashboardInclusion | Apneal</title></Helmet>
        <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
            <Container maxWidth={false}>
                <Box sx={{
                    display: 'flex',
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: 'center',
                    gap: '50px',
                    marginBottom: 3
                }}>
                    {(user?.role === ModelRoles.ADMIN || (user?.role === ModelRoles.CLINICAL_STUDY_ADMIN && clinicalStudyAdmin === ClinicalStudyEnum.EITHEALTH)) &&
                        <DashboardInclusionCard clinicalStudyName={ClinicalStudyEnum.EITHEALTH}
                                                totalInclusionCount={easyPatientsCount}
                                                excludedPatientCount={easyExcludedPatientsCount}

                        />}
                    {(user?.role === ModelRoles.ADMIN || (user?.role === ModelRoles.CLINICAL_STUDY_ADMIN && clinicalStudyAdmin === ClinicalStudyEnum.SESAME)) &&
                        <DashboardInclusionCard clinicalStudyName={ClinicalStudyEnum.SESAME}
                                                totalInclusionCount={sesamePatientsCount}
                                                excludedPatientCount={sesameExcludedPatientsCount}/>}
                </Box>
                <DashboardInclusionTable filteredPatientsRecordings={filteredPatientsRecordings}/>
                {user?.role === ModelRoles.ADMIN &&
                    <DashboardInclusionInvoicingGrid filteredPatientsRecordings={filteredPatientsRecordings}/>
                }
            </Container>
        </Box>
    </>);
}

export default DashboardInclusion;
