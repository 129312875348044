import React, { useState } from 'react';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, ValueFormatterFunc } from 'ag-grid-community';
import { Button, Tooltip } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const formatDateForDisplay: ValueFormatterFunc = (nodeData: any): string => {
    const value: number | undefined = nodeData.value;
    return value ? new Date(value).toLocaleString("fr-FR") : "";
}

type RoutineType = "Once" | "On Server Start";

type Routine = {
    name: string,
    type: RoutineType,
    description: string,
    timesExecuted: number,
    lastExecuted?: number //ms since 1970
};

const RoutinesGrid: React.FC = () => {
    const [rowsData, setRowsData]     = React.useState<Routine[]>([]);

    const populateTable = React.useCallback( (): Routine[] => {
        const table: Routine[] = [];

        table.push({
            name: "DBMigration Example",
            type: "Once",
            description: "Description of the migration that took/will take place.",
            timesExecuted: 0,
        } as Routine);

        table.push({
            name: "Another Routine Example",
            type: "Once",
            description: "Longer description just to see how long we can make the highlight on hover. Longer description just to see how long we can make the highlight on hover. Longer description just to see how long we can make the highlight on hover. Longer description just to see how long we can make the highlight on hover.",
            timesExecuted: 0,
        } as Routine);

        table.push({
            name: "Reindexation Routine Example",
            type: "On Server Start",
            description: "Some routine that reindexes values in a database.",
            timesExecuted: 0,
        } as Routine);

        return table;
    }, []);

    React.useEffect( () => {
        setRowsData( populateTable() )
    }, [populateTable]);

    const executeOnceMore = React.useCallback( (name: string) => {
        setRowsData( (rowsData): Routine[] => {
            return rowsData.map( (row: Routine) => {
                if (row.name != name) return row;
                else return { ...row,
                    timesExecuted: row.timesExecuted + 1,
                    lastExecuted: new Date().valueOf()
                } as Routine;
            });
        });
    }, [setRowsData]);

    const changeFormat = React.useCallback( (routines: Routine[]) => {
        return routines.map( (routine: Routine) => {
            return {
                routine:      <Tooltip title={routine.description}><p>{routine.name}</p></Tooltip>,
                type:         <Tooltip title={routine.type}>{ routine.type == "Once" ? <LooksOneIcon/> : <PowerSettingsNewIcon/> }</Tooltip>,
                execnum:      routine.timesExecuted,
                lastexecuted: routine.lastExecuted,
                executer: (routine.type == "Once" && routine.timesExecuted > 0) ? "" :
                    <Button color={"primary"}
                        onClick={ (event) => executeOnceMore(routine.name) }
                    >Executer</Button>
            }
        });
    }, [executeOnceMore]);

    // Column Definitions: Defines & controls grid columns.
    const [colDefs] = useState<ColDef[]>([
        {
            field: 'routine',
            resizable: true,
            sortable: true,
            filter:true
        },
        {
            field: 'type',
            resizable: true,
            sortable: true,
            filter:true
        },
        {
            field: 'execnum',
            resizable: true,
            sortable: true,
            filter:true
        },
        {
            field: 'lastexecuted',
            resizable: true,
            sortable: true,
            filter:true
        },
        {
            field: 'executer',
            resizable: true,
            sortable: true,
            filter:true,
        },
    ]);


    return (
    <>
        <div className="ag-theme-alpine" style={{height: 300}}>
        <AgGridReact
            onFirstDataRendered={ (event) => event.api.sizeColumnsToFit() }
            rowData={ changeFormat(rowsData) }
            columnDefs={colDefs}
            >

         </AgGridReact>
        </div>
    </>
    )
}

export default RoutinesGrid;