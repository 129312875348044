import React from 'react';
import {
    Card,
    CardActionArea, CardContent,
    Typography
} from "@mui/material";

interface DashboardInclusionCardProps {
    clinicalStudyName: string,
    totalInclusionCount: number,
    excludedPatientCount: number,
}

const DashboardInclusionCard: React.FC<DashboardInclusionCardProps> = ({clinicalStudyName, totalInclusionCount, excludedPatientCount}) => {

    function Pluralization(inclusionCount: number, word: string): string {
        return inclusionCount > 1 ? word + 's' : word;
    }

    return (<>
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {clinicalStudyName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {totalInclusionCount} {Pluralization(totalInclusionCount, 'inclusion')} sur 500.
                        <br />
                        {totalInclusionCount-excludedPatientCount} inclus / {excludedPatientCount} {Pluralization(excludedPatientCount, 'exclu')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </>);
}

export default DashboardInclusionCard;
