import { CombinedState } from 'redux';
import { ChartData } from '../Application/ChartInterface/Plotly/Helpers/DataTypes';
import { ModelRoles } from './API/Authentication/auth.state';
import { AudioEntry, RecordingState } from './ServerTypes';

export type ELabelType = {id: number, name: string, abbreviation: string,
    color: string, shown: boolean, drawing: boolean};
export type SLabelType = {labelTypes: ELabelType[]}

export type ELabel = {id: number, typeid: number, x0: number, x1: number};
export type SLabel = {labels: ELabel[]}

export type ECamera = { center: number, width: number };
export type SCamera = ECamera;

export type EAuth   = { authenticated: boolean, authenticating: boolean, role: ModelRoles };
export type SAuth   = EAuth;

export type ERecordings = { id: string, patient: string, start: number, end: number, state: RecordingState };
export type SRecordings = { recordings: ERecordings[] };

export type ECurrentRecording = { recording?: ERecordings, audio: string, wabuffer: AudioEntry[], buffer: ChartData, buffering: number};
export type SCurrentRecording = ECurrentRecording;

export type EPatients = { id: string, name: string };
export type SPatients = { patients: EPatients[] };

export type EUsers = { email: string, role: string };
export type SUsers = { users: EUsers[] };

export type EInvitations = { email: string, status: string };
export type SInvitations = { invitations: EInvitations[] };

export type EFiles = { s3_bucket: string, s3_key: string, date_created: Date, filesize: number };
export type SFiles = { files: EFiles[] }

export type EChannel = {
    name: string,  color: string,
    order: number, shown: boolean,
    edges: {
        min: number,
        max: number
    },
    domainwidth: number
};
export type SChannel = {
    channels: EChannel[],
    fit: {
        request?: number | "all",
        tick: boolean
    }
};

export type EContentWindow = { lmwidth: number, cmwidth: number, height: number }
export type SContentWindow = EContentWindow;

export type GlobalState = CombinedState<{
    labelType: SLabelType,
    label: SLabel,
    camera: SCamera,

    authentication: SAuth,
    contentWindow: SContentWindow,

    currentRecording: SCurrentRecording,
    recordings: SRecordings,

    patients: SPatients,
    users: SUsers,
    invitations: SInvitations,
    files: SFiles,

    channels: SChannel
}>;


export enum ErrorType {
    GENERIC = "generic",
    LOGIN_WRONG_CREDENTIALS = "login.wrongCredentials",
    TWO_FA_VALIDATION_ERROR = "login.twoFAValidationError",
    PASSWORD_RECOVERY_WRONG_CREDENTIALS = "login.passwordRecoveryWrongCredentials",
    
}

export type ErrorMessage = { errorType: ErrorType, errorCode: number };
