import { Alert, Box, Button, Checkbox, Container, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from '../../hooks/snackbar';
import RecordingService, { Recording } from '../../services/RecordingService';
import { FileUploader } from '../components/FileUploader';
import { ScheduleContext } from '../context/ScheduleContext';

/// The page which contains PSG related tasks and information.
const PSGPage: React.FC = () => {
    const { id } = useParams();

    if (id === undefined) {
        throw new Error("No id provided");
    }

    const [recording, setRecording] = useState<Recording>({} as Recording);
    const [confirmationPseudo, setConfirmationPseudo] = useState<boolean>(false);

    useEffect(() => {
        RecordingService.getRecording(id).then((recording) => {
            setRecording(recording);
        })
    }, []);

    const {setSnackbar} = useSnackbar();
    const navigate = useNavigate()

    return (<ScheduleContext.Provider value={recording}>
        <Helmet><title>PSG | Apneal</title></Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
            <Container maxWidth={false}>
                <Alert severity="warning">
                    Vous vous apprêtez à télécharger la PSG du patient {recording?.patientName}.
                    Avant de procéder Apneal vous rappelle qu’il est de votre responsabilité
                    de télécharger des documents qui ont été pseudonymisés.
                    Pour ce faire, vous pouvez télécharger la procédure de pseudonymisation
                    présentant les différentes étapes en fonction du logiciel que vous utilisez.
                </Alert>

                {confirmationPseudo === false && <ConfirmPseudonymisation validateConfirm={() => setConfirmationPseudo(true)} />}


                {confirmationPseudo &&
                    <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignContent="center" alignItems="center" flexDirection="column">
                        <FileUploader
                            type="PSG"
                            onUploadSuccess={function (data: any): void {
                                setSnackbar({ message: "PSG téléchargée avec succès, vous allez être redirigé." });
                                setTimeout(() => {navigate("/app/recordings")}, 2000);
                            }} onUploadFailure={function (): void {
                                setSnackbar({ message: "Une erreur a eu lieu, merci de réessayer." });
                            }} />
                    </Box>
                }
            </Container>
        </Box>
    </ScheduleContext.Provider>);
}

export default PSGPage;

interface ConfirmPseudonymisationProps {
    validateConfirm: () => void; // Renaming for clarity, though "continue" is a reserved word in JavaScript
}

const ConfirmPseudonymisation: React.FC<ConfirmPseudonymisationProps> = ({ validateConfirm: validateConfirm }) => {
    const [checked, setChecked] = useState<boolean>(false);
    return <>
        <Box sx={{ mt: 3 }} display="flex" height={300} justifyContent="center" alignContent="center" alignItems="center">
            <FormControlLabel control={<Checkbox checked={checked} onClick={() => { setChecked(!checked) }} />} label=" En continuant, je confirme que la PSG a été pseudonymisée" />
        </Box>
        <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Button color="primary" variant="contained" disabled={!checked}
                size="large" onClick={validateConfirm}>Continuer</Button>
        </Box>
    </>
}