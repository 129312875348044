import {Chip, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import RecordingService, {
    Recording,
    RecordingFormStatus,
} from "../../../services/RecordingService";
import {ModelRoles, User} from "../../../hooks/auth";
import {IRowNode} from "ag-grid-community";
import {StatusType} from "../../AGGrids/RecordingsGrid";

export const FormStatusTag = ({data, user, rowNode, callback}: {
    data: Recording,
    user: User,
    rowNode: IRowNode<Recording>,
    callback: (id: string | undefined, formStatus: RecordingFormStatus, statusTag: StatusType) => void
}) => {
    let tagColor;
    let tagText;

    const [anchorEl, setAnchorEl] = useState(null);
    const formEnum = RecordingFormStatus[data.formStatus as keyof typeof RecordingFormStatus];

    const handleClick = (event: any) => {
        if (user.role === ModelRoles.ADMIN) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (formStatus: RecordingFormStatus) => {
        RecordingService.updateRecordingFormStatus(data.id, formStatus).then(() => {
            callback(rowNode.id, formStatus, 'formStatus');
        })
        handleClose();
    };

    switch (formEnum) {
        case RecordingFormStatus.UPLOADED:
            tagColor = 'green';
            tagText = 'Uploaded';
            break;
        case RecordingFormStatus.FORM_OK:
            tagColor = 'darkGreen';
            tagText = 'Form ok';
            break;
        case RecordingFormStatus.ALERT_RAISED:
            tagColor = 'red';
            tagText = 'Alerte levée';
            break;
        case RecordingFormStatus.NOT_UPLOADED:
            tagColor = 'orange';
            tagText = 'Non uploaded';
            break;
        default:
            tagColor = 'gray';
            tagText = 'Not applicable';
    }

    return (
        <>
            <Chip label={tagText} clickable={user.role === ModelRoles.ADMIN} onClick={handleClick}
                  style={{backgroundColor: tagColor, color: 'white'}}/>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(RecordingFormStatus.UPLOADED)}>Uploaded</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingFormStatus.FORM_OK)}>Form ok</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingFormStatus.ALERT_RAISED)}>Alerte levée</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingFormStatus.NOT_UPLOADED)}>Non uploaded</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingFormStatus.NOT_APPLICABLE)}>Non applicable</MenuItem>
            </Menu>
        </>
    );
};



