import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const UrlRenderer: React.FC<ICellRendererParams> = ({ value }) => {
    const urlRegex = /(https?:\/\/[^\s]+)/;

    const renderTextWithLinks = (text: string) => {
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    };

    const renderedText = renderTextWithLinks(value);

    return <div dangerouslySetInnerHTML={{ __html: renderedText }} />;
};

export default UrlRenderer;
