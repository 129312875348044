import { IconButton } from "@mui/material";
import React, { useState } from "react";
import {AuditLog} from "../../services/AuditLogSevice";
import LoupeIcon from '@mui/icons-material/Loupe';
import {AuditTrailDialog} from "./AuditTrailDialog";


export const AuditTrailButton = ({data}: { data: AuditLog }) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    return <>
        <IconButton onClick={()=>setOpenDialog(true)}>
            <LoupeIcon/>
        </IconButton>

        <AuditTrailDialog open={openDialog} data={data} handleClose={() => {
            setOpenDialog(false)
        }}></AuditTrailDialog>
    </>

}
