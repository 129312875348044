import { Chip } from "@mui/material";
import { RecordingStatus } from "../../../services/RecordingService";

export const RecordingStatusTag = ({status}: {status:string}) => {
    let tagColor;
    let tagText;

    const statusEnum:RecordingStatus = RecordingStatus[status as keyof typeof RecordingStatus];
    

    switch (statusEnum) {
        case RecordingStatus.WAITING_FOR_DATA:
            tagColor = '#d2d201';
            tagText = 'En attente de l\'enregistrement';
            break;
        case RecordingStatus.RECORD_SENDING:
            tagColor = 'orange';
            tagText = 'Envoi en cours';
            break;
        case RecordingStatus.DATA_RECEIVED:
            tagColor = 'blue';
            tagText = 'Enregistrement reçu';
            break;
        case RecordingStatus.NEED_RANDOM_CHECK:
            tagColor = 'red';
            tagText = 'Vérification requise';
            break;
        case RecordingStatus.ALERT_RAISED:
            tagColor = 'darkred';
            tagText = 'Alerte levée';
            break;
        case RecordingStatus.DATA_VALIDATED:
            tagColor = 'darkgreen';
            tagText = 'Données validées';
            break;
        case RecordingStatus.INCLUDED:
            tagColor = 'green';
            tagText = 'Inclus';
            break;
        case RecordingStatus.EXCLUDED:
            tagColor = 'grey';
            tagText = 'Exclus';
            break;
        case RecordingStatus.TEST:
            tagColor = 'grey';
            tagText = 'Test';
            break;
        default:
            tagColor = 'gray';
            tagText = 'Unknown';
    }

    return (
        <Chip label={tagText} style={{backgroundColor: tagColor, color: 'white'}} />
    );
};



