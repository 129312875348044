import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import {Box, Container} from "@mui/material";
import {Helmet} from "react-helmet";
import AuditLogService, {AuditLog} from "../../services/AuditLogSevice";
import moment from "moment/moment";
import {useLocation} from "react-router-dom";
import {AuditTrailButton} from "../components/AuditTrailButton";

const AuditTrailGrid: React.FC = () => {

    const [agGridData, setAgGridData] = useState<AuditLog[]>([]);
    const gridRef = useRef<AgGridReact>(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const scheduleId = searchParams.get("scheduleId");

    const dateDisplay = (date: Date): string => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!scheduleId){
                    return
                }
                const auditLogList = await AuditLogService.listAuditLogsByScheduleId(scheduleId);
                const finalModifiedLogs = auditLogList?.map((log) => {
                    return {
                        ...log,
                        patientName: log.schedule.patientName,
                        userEmail: log.user?.email ?? 'lambda',
                        dateTimeFormated: dateDisplay(log.timestamp),
                    };
                });
                setAgGridData(finalModifiedLogs);
            } catch (error) {
                console.error("Une erreur s'est produite :", error);
            }
        };
        fetchData();
    }, []);

    // Column Definitions: Defines & controls grid columns.
    const [colDefs] = useState<ColDef[]>([
        { field: 'scheduleId', flex: 1},
        { field: 'patientName', flex: 0.75 },
        { field: 'userEmail', headerName: "User taking the action", flex: 1 },
        { headerName: `Action taken`, field: 'event', flex: 1 },
        { headerName: `Date and time`, field: 'dateTimeFormated', flex: 1 },
        { headerName: `Other information`, field: 'description', flex: 1 },
        { headerName: `View JSON`, field: 'json',
                cellRenderer: (params: ICellRendererParams) => {
                    return <AuditTrailButton data={params.data}></AuditTrailButton>;
            }, flex: 0.65 }
    ]);

    return (
        <>
            <Helmet><title>Audit Trail | Apneal</title></Helmet>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "82vh" }}>
                        <h2 style={{ paddingBottom: "10px"}}>Audit trail</h2>
                        <AgGridReact
                            rowData={agGridData}
                            rowHeight={40}
                            ref={gridRef}
                            columnDefs={colDefs}
                            pagination={true}
                            paginationPageSize={20}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                filter: true,
                            }}
                        />
                    </div>
                </Container>
            </Box>
        </>
    );
}

export default AuditTrailGrid;
