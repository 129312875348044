import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "../../../hooks/snackbar";
import RecordingService, { Recording, RecordingStatus } from "../../../services/RecordingService";

export const UpdateStatusDialog = ({ schedule, open, handleClose }: { schedule: Recording, open: boolean, handleClose: (status:RecordingStatus) => void }) => {
    const [status, setStatus] = useState<RecordingStatus>(schedule.status as RecordingStatus);
    const {setSnackbar} = useSnackbar();
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Mise à jour du statut
        </DialogTitle>
        <DialogContent dividers>
            <FormControl fullWidth>
                <InputLabel id="schedule-status">Statut</InputLabel>
                <Select
                    labelId="schedule-status"
                    id="schedule-status"
                    value={status}
                    label="Statut"
                    SelectDisplayProps={{style: {paddingTop: '12px'}}}
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value as RecordingStatus)}
                >
                    <MenuItem value={RecordingStatus.WAITING_FOR_DATA} disabled>En attente de données (Night Record Apneal)</MenuItem>
                    <MenuItem value={RecordingStatus.RECORD_SENDING} disabled>En cours d'envoi (Night Record Apneal)</MenuItem>
                    <MenuItem value={RecordingStatus.DATA_RECEIVED}>Données reçues (Night Record Apneal)</MenuItem>
                    <MenuItem value={RecordingStatus.INCLUDED}>Inclus</MenuItem>
                    <MenuItem value={RecordingStatus.TEST}>Test</MenuItem>
                    <MenuItem value={RecordingStatus.DATA_VALIDATED}>Données validées</MenuItem>
                    <MenuItem value={RecordingStatus.ALERT_RAISED}>Levée une alerte</MenuItem>
                    <MenuItem value={RecordingStatus.EXCLUDED}>Exclu</MenuItem>
                    <MenuItem value={RecordingStatus.NEED_RANDOM_CHECK} disabled>Besoin de vérification aléatoire</MenuItem>
                </Select>
                <Button onClick={async () => {
                    await RecordingService.updateRecordingStatus(schedule.id, status).then((recording) => {
                        setSnackbar({ message: "Statut de l'enregistrement mis à jour"});
                    });
                    handleClose(status);
                }}>Modifier le statut</Button>
            </FormControl>
        </DialogContent>
    </Dialog>
}