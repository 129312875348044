import axios from "axios";
import { User } from "../Redux/ServerTypes";
import { idText } from "typescript";


const UserService = {

    /**
     * Get All users from the server 
     * Route is protected for only ADMIN roles
     */
    getAllUsers: async () => {
        return axios.get("/users").then( (response) => {
            return response.data as User[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    getUserById: async (id: string) => {
        return axios.get("/users/" + id).then( (response) => {
            return response.data as User;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    createUser: async (user: User) => {
        return axios.post("/users", user).then( (response) => {
            return response.data as User;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    updateUser: async (userId: string, user: User) => {
        return axios.put("/users/" + userId, user).then( (response) => {
            return response.data as User;
        }).catch( (error) => {
            throw new Error(error);
        });
    }
}

export default UserService;
